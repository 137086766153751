import {format} from 'date-fns';
import * as React from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'; 
import {IHistory} from '../../../interfaces';

export interface IListItemProps {
    instance?: any;
    index?: number;
    frequency?: string;
    listItemProp?: string;
    history?: IHistory;
}

export class ListItemImpl extends React.PureComponent<IListItemProps, {}> {

    static defaultProps: IListItemProps;
    renderDetailPage() {
        const {instance, index, history, frequency} = this.props;
        const {plantId, plantRegionName, facilityType, permitType, inspectionName, inspectionId, submittedDate, lastUpdatedDate, parentInspectionName, FormId } = instance;
        instance.frequency = frequency;
        localStorage.setItem('reportsMetaData', JSON.stringify(instance));
        history?.push(`/reports/${frequency}/${inspectionId}/${FormId}`);
    }
    render() {
        const {instance, index, history, frequency} = this.props;
        if (!instance) {
            return <div/>;
        }
        const {plantId, plantRegionName, facilityType, permitType, inspectionName, inspectionId, submittedDate, lastUpdatedDate} = instance;
        return <Row className="form-list-item" onClick={() => { this.renderDetailPage();
           
        }}>
            <Col className="clickable-list-item serial-no"
                 sm={1}
                 xs={1}
            >
                {index}
            </Col>
            <Col className="clickable-list-item"
                 xs={1}
                 sm={1}
            >
                {plantId}
            </Col>
            <Col className="clickable-list-item"
                 xs={2}
                 sm={2}
            >
                {plantRegionName}
            </Col>
            <Col className="clickable-list-item"
                 xs={1}
                 sm={1}
            >
                {facilityType}
            </Col>
            <Col className="clickable-list-item"
                 xs={1}
                 sm={1}
            >
                {permitType}
            </Col>
            <Col className="clickable-list-item"
                 xs={2}
                 sm={2}
            >
                {inspectionName}
            </Col>
            <Col className="clickable-list-item"
                 xs={2}
                 sm={2}
            >
                {submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z'), 'MM/dd/yyyy') : 'NA'}
            </Col>

            <Col className="clickable-list-item"
                 xs={2}
                 sm={2}
            >
                {lastUpdatedDate ? format(new Date(lastUpdatedDate + 'T17:00:00.00Z'), 'MM/dd/yyyy') : 'NA'}
            </Col>
        </Row>;
    }
}

export function mapStateToProps(state) {
    return { };
}

export const ListItem = withRouter(connect< IListItemProps, any, any>(mapStateToProps)(ListItemImpl as any));
