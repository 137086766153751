import * as React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import './InBevReportDetailer.scss';
import {format} from 'date-fns';
import {Img} from '../../reusableComponents/ImageGallery/Img';
import {showCarousel} from '../../../actions/miscellaneousActions';
import {MarkAsCompletedPopOver} from '../../reusableComponents/MarkAsCompletePopOver';
import {saveFlagCompletions} from '../../../services/dailyFormService';
import {calculateActualTitleDate, convertTime, getAnswerWithFlag} from '../../../utils/generalUtils';
import loadingImage from '../../reusableComponents/DetailsPageWrapper/signLoading.gif';
import textThumb from '../../../images/text-thumb.png';
import videoThumb from '../../../images/video-thumb.png';
import excelThumb from '../../../images/excel-thumb.jpg';
import wordThumb from '../../../images/word-thumb.png';
import pdfThumb from '../../../images/pdf-thumb.png'; 
import pptThumb from '../../../images/ppt-thumb.jpg';

export interface IInBevReportDetailerState {
    formData: any;
    signBase64: string;
}

export interface IInBevReportDetailerProps {
    formData: any;
    onopenInNewViewTab: Function;
    onFlagUpdate: () => void;
}

export class InBevReportDetailerImpl extends React.Component<IInBevReportDetailerProps, IInBevReportDetailerState> {

    reportsMetaData = JSON.parse(localStorage.getItem('reportsMetaData') || '{}');
    listOfNWQuestions: Array<any> = [];
    questionCount = 0;

    constructor(props: IInBevReportDetailerProps) {
        super(props);
        this.state = {
            signBase64: '',
            formData: props.formData || {}
        };
    }

    componentWillReceiveProps(nextProps) {
        this.forceUpdate();
        this.setState({
            signBase64: '',
            formData: nextProps.formData || {}
        });
    }

    renderTitles = (quest) => {
        return (
            <tr key={quest.questionId} className="main-title">
                <td colSpan={4}>{quest.question}</td>
            </tr>
        );
    } 
    renderSecondaryTitles = (quest) => {
        return (
            <tr key={quest.questionId} className="secondary-title">
                <td colSpan={4}> {quest.question}</td> 
            </tr>
        );
    }
    renderParagraph = (quest: any) => {
        let ques = quest.question.split('~')
        return (
            <div className="question-row">
                <div key={quest.questionId} className="field-paragraph"> 
                    {
                        (ques || []).map( (item) => {
                            return <p dangerouslySetInnerHTML={{__html: item}}
                            />
                        })
                    }
                </div> 
            </div>
        )
    }  
    getQuestionCount = (questData) => {
        return (questData && questData.question && (questData.questionType !== 'Title' && questData.questionType !== 'Subtitle')) ? this.questionCount += 1 : null;
    } 
    renderObjectiveTypeQuestions = (quest, localQuestNo, isSubQuest) => {
        const questionNo = this.getQuestionCount(quest);
        if (quest.answer === 'Needs Work') {
            this.listOfNWQuestions.push(questionNo + ') Needs work - ' + quest.subQuestions[0].answer);
        }
        return (
            <React.Fragment key={quest.questionId}>
                <tr className="question-row">
                    <td className="question">
                        {questionNo + ') ' + quest.question}
                    </td>
                    <td className="answer">
                        <span className="pp-printable-data">{quest.answer === 'Needs Work' ? <span className="fa fa-check"/> : <span/>}</span>
                        <span className="d-print-none">{quest.answer === 'Needs Work' ? quest.subQuestions[0].answer : <span/>}</span>
                    </td>
                    <td>
                        {quest.answer === 'Ok' ? <span className="fa fa-check"/> : <span/>}
                    </td>
                    <td>
                        {quest.answer === 'N/A' ? <span className="fa fa-check"/> : <span/>}
                    </td>
                </tr> 
            </React.Fragment>
        );
    } 
    renderComments = (quest ) => {
        return <div className="question-row" key={quest.questionId}>
            <div className={'question'}>
                {quest.question}
            </div>
            <div className={'answer'}>{quest.answer || 'Unanswered'}</div>
        </div>;
    } 
    renderDate = (quest, questNo, isSubQuest) => {
        return (
            <div className="question-row" key={quest.questionId}>
                <div className="question">
                    {quest.question}
                </div>
                <div className="answer">{quest.answer ? format(new Date(quest.answer), 'MM-dd-yyyy') : 'Unanswered'}</div>
            </div>
        );
    } 
    generateQuestionAnswer = (quest, questNo, isSubQuest?: boolean) => {
        if (quest.questionType) {
            quest.questionType = quest.questionType.trim();
        }
        switch (quest.questionType) {
            case 'Title': {
                return this.renderTitles(quest);
            }
            case 'Subtitle': {
                return this.renderSecondaryTitles(quest);
            }
            case 'Hint': {
                return this.renderSecondaryTitles(quest);
            }
            case 'Paragraph':
                return this.renderParagraph(quest);
            case 'Comments':
                return this.renderComments(quest);
            case 'ObjectiveType': { 
                return this.renderObjectiveTypeQuestions(quest, questNo, isSubQuest); 
            } 
        }
    }
    renderReports() {
        const { parentInspectionName } = this.reportsMetaData 
        switch(parentInspectionName) {
            case 'InBevVisibleOpacity':
                return this.renderInBevVisibleOpacity(); 
            case 'InBevBrewingRoofVent':
                return this.renderInBevBrewingRoofVent(); 
            case 'InBevFilterCellerArea':
                return this.renderInBevFilterCellerArea(); 
            case 'InBevBrewhouseArea': 
                return this.renderInBevBrewhouseArea(); 
            case 'InBevGrainsUploadingArea': 
                return this.renderInBevGrainsUploadingArea(); 
        }
    }
    renderInBevGrainsUploadingArea() {
        const {questionAndAnswer } = this.props.formData;
        return (
            <div className='InBev-report-table-container' style={{paddingTop: '15px'}}> 
                <table className='InBevBrewhouseArea'>
                    <thead>
                        <tr>
                            <th className="table-col-insTitle">Dust Collection System</th>
                            <th className="table-col-pressure">Pressure Drop Reading (in) ({'>'}.5 DP {'<'}10)</th>
                            <th className="table-col-fan">Fan & Motor Bearings</th>
                            <th className="table-col-duct">System Duct Works & Hoods</th> 
                            <th className="table-col-workOrder">Work Order #</th> 
                            <th className="table-col-comments">Other Comments</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderBrewhouseAreaAnswers(0)}
                        {this.renderBrewhouseAreaAnswers(6)} 
                        {this.renderBrewhouseAreaWithPFAnswers(12)} 
                        {this.renderBrewhouseAreaAnswers(19)} 
                        {this.renderBrewhouseAreaAnswers(25, 'Stocks')} 
                        {this.renderBrewhouseAreaWithPFAnswers(31)} 
                        {this.renderBrewhouseAreaWithPFAnswers(38)} 
                        {this.renderBrewhouseAreaAnswers(45, 'Stocks')}
                        {this.renderBrewhouseAreaAnswers(51, 'Stocks')}
                        {this.renderBrewhouseAreaAnswers(57)} 
                    </tbody>
                </table>
                <div className="requirements-container">
                    <Row className="show-grid col-12">
                        <Col xs={12}>
                            <h3>REQUIREMENTS:</h3> 
                        </Col>
                        { this.generateQuestionAnswer(questionAndAnswer[64], 1, false) }  
                    </Row>
                </div>
                <Row> 
                    { this.generateQuestionAnswer(questionAndAnswer[65], 1, false) }  
                </Row>
                <div style={{marginTop: '10px'}}>
                    <Row className="show-grid col-12">
                        <Row className="show-grid col-3">
                            <Col xs={12}
                                className="details-label"
                                style={{textAlign: 'left'}} sm={12}>{questionAndAnswer[66]['question']}:</Col>
                            <Col className="details-value"
                                sm={12} xs={12} id="detailsChild">{questionAndAnswer[66]['answer'] || 'N/A'}</Col>
                        </Row>
                        <Row className="show-grid col-3">
                            <Col xs={12}
                                className="details-label"
                                style={{textAlign: 'left'}} sm={12}>{questionAndAnswer[67]['question']}:</Col>
                            <Col className="details-value"
                                sm={12} xs={12} id="detailsChild">{questionAndAnswer[67]['answer'] || 'N/A'}</Col>
                        </Row>
                        <Row className="show-grid col-4">
                            <Col xs={12}
                                className="details-label"
                                style={{textAlign: 'left'}} sm={12}>{questionAndAnswer[68]['question']}:</Col>
                            <Col className="details-value"
                                sm={12} xs={12} id="detailsChild">{questionAndAnswer[68]['answer'] || 'N/A'}</Col>
                        </Row>
                        <Row className="show-grid col-2">
                            <Col xs={12}
                                className="details-label"
                                style={{textAlign: 'left'}} sm={12}>{questionAndAnswer[69]['question']}:</Col>
                            <Col className="details-value"
                                sm={12} xs={12} id="detailsChild">{questionAndAnswer[69]['answer'] || 'N/A'}</Col>
                        </Row>
                    </Row>
                </div>
            </div>
        )
    }
    renderInBevBrewhouseArea() { 
        const {questionAndAnswer } = this.props.formData;
        return (
            <div className='InBev-report-table-container' style={{paddingTop: '15px'}}> 
                <table className='InBevBrewhouseArea'>
                    <thead>
                        <tr>
                            <th className="table-col-insTitle">Dust Collection System</th>
                            <th className="table-col-pressure">Pressure Drop Reading (in) ({'>'}.5 DP {'<'}10)</th>
                            <th className="table-col-fan">Fan & Motor Bearings</th>
                            <th className="table-col-duct">System Duct Works & Hoods</th> 
                            <th className="table-col-workOrder">Work Order #</th> 
                            <th className="table-col-comments">Other Comments</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderBrewhouseAreaAnswers(0)}
                        {this.renderBrewhouseAreaWithPFAnswers(6)}
                        {this.renderBrewhouseAreaWithPFAnswers(13)}
                        {this.renderBrewhouseAreaAnswers(20, 'Stocks')}
                        {this.renderBrewhouseAreaAnswers(26)}
                        {this.renderBrewhouseAreaAnswers(32, 'Stocks')}
                        {this.renderBrewhouseAreaAnswers(38, 'Stocks')}
                        {this.renderBrewhouseAreaAnswers(44, 'Stocks')}
                        {this.renderBrewhouseAreaAnswers(50, 'Stocks')}
                        {this.renderBrewhouseAreaAnswers(56, 'Cart')}
                        {this.renderBrewhouseAreaAnswers(62, 'Cart')}
                    </tbody>
                </table>
                <div className="requirements-container">
                    <Row className="show-grid col-12">
                        <Col xs={12}>
                            <h3>REQUIREMENTS:</h3> 
                        </Col>
                        { this.generateQuestionAnswer(questionAndAnswer[69], 1, false) }  
                    </Row>
                </div>
                <Row> 
                    { this.generateQuestionAnswer(questionAndAnswer[70], 1, false) }  
                </Row>
                <div style={{marginTop: '10px'}}>
                    <Row className="show-grid col-12">
                        <Row className="show-grid col-3">
                            <Col xs={12}
                                className="details-label"
                                style={{textAlign: 'left'}} sm={12}>{questionAndAnswer[71]['question']}:</Col>
                            <Col className="details-value"
                                sm={12} xs={12} id="detailsChild">{questionAndAnswer[71]['answer'] || 'N/A'}</Col>
                        </Row>
                        <Row className="show-grid col-3">
                            <Col xs={12}
                                className="details-label"
                                style={{textAlign: 'left'}} sm={12}>{questionAndAnswer[72]['question']}:</Col>
                            <Col className="details-value"
                                sm={12} xs={12} id="detailsChild">{questionAndAnswer[72]['answer'] || 'N/A'}</Col>
                        </Row>
                        <Row className="show-grid col-4">
                            <Col xs={12}
                                className="details-label"
                                style={{textAlign: 'left'}} sm={12}>{questionAndAnswer[73]['question']}:</Col>
                            <Col className="details-value"
                                sm={12} xs={12} id="detailsChild">{questionAndAnswer[73]['answer'] || 'N/A'}</Col>
                        </Row>
                        <Row className="show-grid col-2">
                            <Col xs={12}
                                className="details-label"
                                style={{textAlign: 'left'}} sm={12}>{questionAndAnswer[74]['question']}:</Col>
                            <Col className="details-value"
                                sm={12} xs={12} id="detailsChild">{questionAndAnswer[74]['answer'] || 'N/A'}</Col>
                        </Row>
                    </Row>
                </div>
            </div>
        )
    }
    renderBrewhouseAreaAnswers(index, pressureType?: string) {
        const {questionAndAnswer } = this.props.formData;
        return (
            <tr>
                <td className="table-col-insTitle">{questionAndAnswer[index]['question']}</td>
                <td className="table-col-pressure">{ (pressureType && questionAndAnswer[index+1]['answer']?`${pressureType}: `:'') +  (questionAndAnswer[index+1]['answer'] || '').toString() }</td>
                <td className="table-col-fan">{this.renderAnswer(index+2)}</td>
                <td className="table-col-duct">{this.renderAnswer(index+3)}</td>
                <td className="table-col-workOrder">{this.renderAnswer(index+4)}</td>
                <td className="table-col-comments">{this.renderAnswer(index+5)}</td> 
            </tr>
        )
    }
    renderBrewhouseAreaWithPFAnswers(index) {
        const {questionAndAnswer } = this.props.formData;
        return (
            <tr>
                <td className="table-col-insTitle">{questionAndAnswer[index]['question']}</td>
                <td className="table-col-pressure">
                    <div>Stocks: {questionAndAnswer[index+1]['answer']}</div>
                    <div>PF: {questionAndAnswer[index+2]['answer']}</div> </td>
                <td className="table-col-fan">{this.renderAnswer(index+3)}</td>
                <td className="table-col-duct">{this.renderAnswer(index+4)}</td>
                <td className="table-col-workOrder">{this.renderAnswer(index+5)}</td>
                <td className="table-col-comments">{this.renderAnswer(index+6)}</td> 
            </tr>
        )
    }
    renderInBevFilterCellerArea() {
        const {questionAndAnswer } = this.props.formData;
        return (
            <div className='InBev-report-table-container' style={{paddingTop: '15px'}}> 
                <table className='InBevFilterCellerArea'>
                    <thead>
                        <tr>
                            <th className="table-col-insTitle">Dust Collection System</th>
                            <th className="table-col-pressure">Pressure Drop Reading (in) ({'>'}.5 DP {'<'}10)</th>
                            <th className="table-col-fan">Fan & Motor Bearings</th>
                            <th className="table-col-duct">System Duct Works & Hoods</th> 
                            <th className="table-col-workOrder">Work Order #</th> 
                            <th className="table-col-comments">Other Comments</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderFilterCellerAreaAnswers(0)}
                        {this.renderFilterCellerAreaAnswers(6)}
                    </tbody>
                </table>
                <div className="requirements-container">
                    <Row className="show-grid col-12">
                        <Col xs={12}>
                            <h3>REQUIREMENTS:</h3> 
                        </Col>
                        { this.generateQuestionAnswer(questionAndAnswer[13], 1, false) }  
                    </Row>
                </div>
                <Row> 
                    { this.generateQuestionAnswer(questionAndAnswer[14], 1, false) }  
                </Row>
                <div style={{marginTop: '10px'}}>
                    <Row className="show-grid col-12">
                        <Row className="show-grid col-3">
                            <Col xs={12}
                                className="details-label"
                                style={{textAlign: 'left'}} sm={12}>{questionAndAnswer[15]['question']}:</Col>
                            <Col className="details-value"
                                sm={12} xs={12} id="detailsChild">{questionAndAnswer[15]['answer'] || 'N/A'}</Col>
                        </Row>
                        <Row className="show-grid col-3">
                            <Col xs={12}
                                className="details-label"
                                style={{textAlign: 'left'}} sm={12}>{questionAndAnswer[16]['question']}:</Col>
                            <Col className="details-value"
                                sm={12} xs={12} id="detailsChild">{questionAndAnswer[16]['answer'] || 'N/A'}</Col>
                        </Row>
                        <Row className="show-grid col-4">
                            <Col xs={12}
                                className="details-label"
                                style={{textAlign: 'left'}} sm={12}>{questionAndAnswer[17]['question']}:</Col>
                            <Col className="details-value"
                                sm={12} xs={12} id="detailsChild">{questionAndAnswer[17]['answer'] || 'N/A'}</Col>
                        </Row>
                        <Row className="show-grid col-2">
                            <Col xs={12}
                                className="details-label"
                                style={{textAlign: 'left'}} sm={12}>{questionAndAnswer[18]['question']}:</Col>
                            <Col className="details-value"
                                sm={12} xs={12} id="detailsChild">{questionAndAnswer[18]['answer'] || 'N/A'}</Col>
                        </Row>
                    </Row>
                </div>
                
            </div>
        );
    }
    renderFilterCellerAreaAnswers(index) {
        const {questionAndAnswer } = this.props.formData;
        return (
            <tr>
                <td className="table-col-insTitle">{questionAndAnswer[index]['question']}</td>
                <td className="table-col-pressure">{this.renderAnswer(index+1)}</td>
                <td className="table-col-fan">{this.renderAnswer(index+2)}</td>
                <td className="table-col-duct">{this.renderAnswer(index+3)}</td>
                <td className="table-col-workOrder">{this.renderAnswer(index+4)}</td>
                <td className="table-col-comments">{this.renderAnswer(index+5)}</td> 
            </tr>
        )
    } 
    renderInBevBrewingRoofVent() {
        const {questionAndAnswer } = this.props.formData;
        return (
            <><Row className="show-grid col-3">
                <Col xs={6}
                    className="details-label"
                    style={{textAlign: 'right'}} sm={6}>{questionAndAnswer[0]['question']}:</Col>
                <Col className="details-value"
                    sm={6} xs={6} id="detailsChild">{questionAndAnswer[0]['answer'] || 'N/A'}</Col>
            </Row>
            <Row className="show-grid col-3">
                <Col xs={6}
                    className="details-label"
                    style={{textAlign: 'right'}} sm={6}>{questionAndAnswer[1]['question']}:</Col>
                <Col className="details-value"
                    sm={6} xs={6} id="detailsChild">{questionAndAnswer[1]['answer'] || 'N/A'}</Col>
            </Row>
            <Row className="show-grid col-6">
                <Col xs={5}
                    className="details-label"
                    style={{textAlign: 'right'}} sm={5}>{questionAndAnswer[2]['question']}:</Col>
                <Col className="details-value"
                    sm={7} xs={7} id="detailsChild">{questionAndAnswer[2]['answer'] || 'N/A'}</Col>
            </Row>
            <div className='InBev-report-table-container' style={{paddingTop: '15px'}}> 
                <table className='InBevBrewingRoofVent'>
                    <thead>
                    <tr>
                        <th className="table-col-insTitle"></th>
                        <th className="table-col-evidence">Evidence of release?</th>
                        <th className="table-col-time">Time</th>
                        <th className="table-col-comments">Comments</th> 
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderBrewingRoofVentAnswers(3)}
                        {this.renderBrewingRoofVentAnswers(7)}
                        {this.renderBrewingRoofVentAnswers(11)}
                        {this.renderBrewingRoofVentAnswers(15)}
                        {this.renderBrewingRoofVentAnswers(19)}
                        {this.renderBrewingRoofVentAnswers(23)}
                        {this.renderBrewingRoofVentAnswers(27)}
                        {this.renderBrewingRoofVentAnswers(31)}
                        {this.renderBrewingRoofVentAnswers(35)}
                        {this.renderBrewingRoofVentAnswers(39)}
                        {this.renderBrewingRoofVentAnswers(43)}
                        {this.renderBrewingRoofVentAnswers(47)}
                    </tbody>
                </table>
            </div>
            <Row className="show-grid col-12" style={{marginTop: '10px'}}>
                <Row className="show-grid col-12">
                    <Col xs={3}
                        className="details-label"
                        style={{textAlign: 'right'}} sm={3}>{questionAndAnswer[51]['question']}:</Col>
                    <Col className="details-value"
                        sm={9} xs={9} id="detailsChild">{questionAndAnswer[51]['answer'] || 'N/A'}</Col>
                </Row>
                <Row className="show-grid col-6">
                    <Col xs={6}
                        className="details-label"
                        style={{textAlign: 'right'}} sm={6}>{questionAndAnswer[52]['question']}:</Col>
                    <Col className="details-value"
                        sm={6} xs={6} id="detailsChild">{questionAndAnswer[52]['answer'] || 'N/A'}</Col>
                </Row>
                <Row className="show-grid col-6">
                    <Col xs={6}
                        className="details-label"
                        style={{textAlign: 'right'}} sm={6}>{questionAndAnswer[54]['question']}:</Col>
                    <Col className="details-value"
                        sm={6} xs={6} id="detailsChild">{questionAndAnswer[54]['answer'] || 'N/A'}</Col>
                </Row>
                { this.generateQuestionAnswer(questionAndAnswer[53], 1, false) }  
            </Row>

             
            
            </>
        );
    }
    renderBrewingRoofVentAnswers(index) {
        const {questionAndAnswer } = this.props.formData;
        return (
            <tr>
                <td className="table-col-insTitle">{questionAndAnswer[index]['question']}</td>
                <td className="table-col-evidence">{this.renderAnswer(index+1)}</td>
                <td className="table-col-time">{questionAndAnswer[index+3]['answer'] ? convertTime(questionAndAnswer[index+3]['answer']): ''}</td>
                <td className="table-col-comments">{this.renderAnswer(index+2)}</td> 
            </tr>
        )
    } 
    renderInBevVisibleOpacity() {
        const {questionAndAnswer } = this.props.formData;
        return (
            <div className='InBev-report-table-container' style={{paddingTop: '15px'}}>
                { this.generateQuestionAnswer(questionAndAnswer[0], 1, false) }
                <table className='InBevVisibleOpacity'>
                    <thead>
                    <tr>
                        <th className="table-col-insArea"></th>
                        <th className="table-col-inOp">In Operation?</th>
                        <th className="table-col-opOb">Opacity Observed?</th>
                        <th className="table-col-insDate">Date of Inspection</th>
                        <th className="table-col-insName">Name of Inspection</th>
                        <th className="table-col-cmnts">Comments</th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.renderVisibleOpacityAnswers(1)}
                        {this.renderVisibleOpacityAnswers(7)}
                        {this.renderVisibleOpacityAnswers(13)}
                        {this.renderVisibleOpacityAnswers(19)}
                        {this.renderVisibleOpacityAnswers(25)}
                        {this.renderVisibleOpacityAnswers(31)}
                        <tr><td colSpan={6}>*If yes for 4, 5 or 6 boiler, climb up to top of structure to identify which boiler.</td></tr>
                        {this.renderVisibleOpacityAnswers(37)}
                    </tbody>
                </table>
                <Row style={{paddingTop:'10px'}}>
                    { this.generateQuestionAnswer(questionAndAnswer[43], 1, false) } 
                </Row>
                <Row className=" "><span className="question">Overall Comments : </span><span className="answer">{questionAndAnswer[44]['answer']}</span></Row> 
                <Row className="show-grid verification-signatures">
                    <Col sm={12} style={{fontWeight: 600, textAlign:'center'}}>Verification Signatures</Col> 
                    <Col sm={{span: 3, offset: 4}}>Powerhouse Supervisor:</Col>
                    <Col sm={5}>{questionAndAnswer[46]['answer']}</Col>
                    <Col sm={{span: 3, offset: 4}}>EHS Manager:</Col>
                    <Col sm={5}>{questionAndAnswer[47]['answer']}</Col>
                </Row>

            </div>
        );
    }
    renderVisibleOpacityAnswers(index) {
        const {questionAndAnswer } = this.props.formData;
        return (
            <tr>
                <td className="table-col-insArea">{questionAndAnswer[index]['question']}</td>
                <td className="table-col-inOp">{this.renderAnswer(index+1)}</td>
                <td className="table-col-opOb">{this.renderAnswer(index+2)}</td>
                <td className="table-col-insDate">{this.renderAnswer(index+3)}</td>
                <td className="table-col-insName">{this.renderAnswer(index+4)}</td>
                <td className="table-col-cmnts">{this.renderAnswer(index+5)}</td>
            </tr>
        )
    }
    
    renderImages = () => {
        const {ImageUrls, SignatureUrls, isDeclarationRequired} = this.props.formData;
        const {onopenInNewViewTab} = this.props;

        return <div className="declarations" key={Date.now()}>
            <hr className="line-break"/>
            <div className="images-container">
                <div className="image-label">Attachments :</div>
                <div className="images-list">
                    {ImageUrls && ImageUrls.length > 0 ? ImageUrls.map((url, index) => {
                        let fileDet = url.fileName.split('.');  
                        const fileExtension = fileDet[fileDet.length - 1];
                        let imageThumb = '';
                        let fileFormat = 'NotPDF';
                        // let tooltip = fileSplit[0].split('~~')[0] + '.' + fileSplit[fileSplit.length-1];
                        switch(fileExtension.toLowerCase()) {
                            case 'pdf': imageThumb = pdfThumb; fileFormat = 'PDF'; break;
                            case 'doc': case 'docx': imageThumb = wordThumb; break; 
                            case 'xlx': case 'xls': case 'csv': case 'xlsx': imageThumb = excelThumb; break; 
                            case 'txt': imageThumb = textThumb; break;
                            case 'mp4': imageThumb = videoThumb; break; 
                            case 'pptx': case 'ppt': imageThumb = pptThumb; break;
                            case 'png': case 'jpg': case 'jpeg': /* case 'JPG': case 'PNG': case 'JPEG': */ imageThumb = url.imageURL; break;
                            // default: imageThumb = ''; this.invalidFile++; break; 
                            default: imageThumb = ''; break; 
                        } 
                        return <div
                            key={index}
                            title="Click to view in full screen mode."
                            className="image">
                                {fileFormat == "PDF" ?
                                    <span onClick={() => onopenInNewViewTab(url.imageURL)} >
                                    <Img src={imageThumb} onClick={() => showCarousel(url.imageURL)} width="100px"  height="100px"/></span>
                                : 
                                    <a href={url.imageURL} target="_blank" title="Click to view this file" aria-hidden="true" >
                                    <Img src={imageThumb} onClick={() => showCarousel(url.imageURL)} width="100px"  height="100px"/></a>
                                }
                        </div>;
                    }) : <div
                        style={{fontSize: '16px', padding: '2vh 0', color: 'gray'}}>
                        No Attachments to this form.
                    </div>
                    }
                </div>
            </div> 
        </div>;
    }

    renderAnswer(index) { 
        const {questionAndAnswer } = this.props.formData;
        let quest = questionAndAnswer[index];
        let answer = questionAndAnswer[index]['answer'];
        let { followUpLogo, followUpClass, defaultValue } = getAnswerWithFlag(quest) 
        /* let followUpLogo = '';
        let followUpClass = '';
        let defaultValue = {};
        if(quest.isFlagged ) {
            if(quest.followUpRemark) {
                followUpLogo = 'fa fa-check-square-o';
                followUpClass = 'followUpRemarkComplete';
                defaultValue = {'followUpRemark': quest.followUpRemark, 'followUpDate': quest.followUpDate }
            } else {
                followUpLogo = 'fa fa-flag';
                followUpClass = 'followUpRemarkUnComplete';
            }
        }  */
        return (
            quest.isFlagged && quest.followUpOn===quest.answer ? <div className="flagged-answer"> <MarkAsCompletedPopOver ref="popOver" formSubmittedDate={this.reportsMetaData.submittedDate} questionId={quest.questionId} onUpdate={this.handleFlagAnswerUpdate} defaultValue={defaultValue}>
                    <div className={`${followUpClass} mark-as-complete-col`}
                         title="Click to enter the follow-up comment">
                        <i className={followUpLogo} aria-hidden="true"> </i> {answer}
                    </div> 
            </MarkAsCompletedPopOver> </div> : answer
        )
    }
    handleFlagAnswerUpdate = async (comments, date, questionId) => {
        await saveFlagCompletions(this.reportsMetaData.inspectionId, questionId, comments, format(new Date(date), 'MM-dd-yyyy'),undefined,'InBevReportDetailer').then((res) => {
            if (this.props.onFlagUpdate) {
                this.props.onFlagUpdate();
            }
        });
    }

    render() {
        const {questionAndAnswer, submittedBy, submittedDate, plantId, updatedBy, PsName, lastUpdatedDate, isPermanent} = this.props.formData;
        const {inspectionName, frequency, actualDate, facilityType, permitType} = this.reportsMetaData;
        return <div style={{padding: '20px'}} id="details-page" className="reports-page-container">
            <div className="report-title">{`${inspectionName} - ${calculateActualTitleDate(frequency, actualDate)}`}</div>
            <div className="pp-printable-data">
                <div className="metadata-set"><span className="question">Plant ID:</span><span className="answer">{plantId}</span></div>
                <div className="metadata-set d-print-none"><span className="question">Facility Type:</span><span className="answer">{facilityType}</span></div>
                <div className="metadata-set d-print-none"><span className="question">Permit Type:</span><span className="answer">{permitType}</span></div>
                <Col className="metadata-set d-print-none"><span className="question">Permanent:</span><span className="answer">{isPermanent}</span></Col>
                <Col className="metadata-set d-print-none"><span className="question">Outfall:</span><span className="answer">{PsName || 'NA'}</span></Col>
                <div className="metadata-set"><span className="question">Submitted:</span><span className="answer">{submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z' ), 'MM-dd-yyyy') : 'NA'}</span></div>
                <div className="metadata-set"><span className="question">Submitted By:</span><span className="answer">{submittedBy || 'NA'}</span></div>
                <Col className="metadata-set d-print-none"><span className="question">Last Updated:</span><span className="answer">{lastUpdatedDate ? format(new Date(lastUpdatedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col className="metadata-set d-print-none"><span className="question">Updated By:</span><span className="answer">{updatedBy || 'NA'}</span></Col>
            </div>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Plant ID:</span><span className="answer">{plantId}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Facility Type:</span><span className="answer">{facilityType}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Outfall:</span><span className="answer">{PsName || 'NA'}</span></Col>

            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Permit Type:</span><span className="answer">{permitType}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Submitted:</span><span className="answer">{submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Submitted By:</span><span className="answer">{submittedBy || 'NA'}</span></Col>
            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Permanent:</span><span className="answer">{isPermanent}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Last Updated:</span><span className="answer">{lastUpdatedDate ? format(new Date(lastUpdatedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Updated By:</span><span className="answer">{updatedBy || 'NA'}</span></Col>
            </Row>
            <Row style={{marginTop:'10px'}}>
                {this.renderReports()}
            </Row>
            {this.renderImages()} 
        </div>;
    }
}

export function mapStateToProps(state, ownProps) {
    return {};
}

export const InBevReportDetailer = connect<{}, IInBevReportDetailerState, IInBevReportDetailerProps>(mapStateToProps)(InBevReportDetailerImpl as any);

const Label = ({children, isFlagged, icon}) => {
    return <div
        style={{fontSize: '18px', color: `${isFlagged ? '#A4BF43' : 'gray'}`}}
    >
        {isFlagged ?
            icon
            : ''}
        {children}
    </div>;
};

const Value = ({children}) => {
    return <div style={{fontSize: '18px', marginBottom: '0.5vh'}}>{children}</div>;
};
