import * as React from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Dialog from 'react-bootstrap-dialog';

import {IHistory} from '../../interfaces';
import {UserModel} from '../../model/UserModel';
import { updatePasswordUser } from '../../services/userService';
import { Loader } from '../reusableComponents/Loader';
import { getUserData } from '../../services/loginService';

export interface IUserListItemProps {
    instance?: UserModel;
    index?: number;
    history?: IHistory;

    onClick?(val): string;
}

export interface IUserListItemState { 
    isLoading: boolean;
}

export class UserListItemImpl extends React.PureComponent<IUserListItemProps, IUserListItemState> {
    dialog: Dialog;
    static defaultProps: IUserListItemProps;
    loggedInUserInfo = getUserData();
    
    constructor(props: IUserListItemProps) {
        super(props);
        this.state = {isLoading: false};
    }
    componentDidMount() {
        Dialog.setOptions({
            defaultOkLabel: 'Yes',
            defaultCancelLabel: 'No',
        });
    }
    handleUserPasswordUpdate = () => { 
        this.dialog.show({
            body: `Are you sure you want to send password for user ${this.props.instance?.props.First_Name}?`,
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction(() => this.updateUserPassword() )
            ]
        });
    }
    
    updateUserPassword = async () => {
        this.setState({ isLoading:true });
        this.props.instance && await updatePasswordUser(this.props.instance.props.id, this.props.history);
        this.setState({ isLoading:false });
        return true;
    }
    render() {
        const {index, instance, history} = this.props;
        return <Row className="form-list-item">
            {this.state.isLoading ? <Loader type="submission"/> : ''}
            {
                // @ts-ignore
                <Dialog className="confirmation-modal" ref={(el: any) => {
                    this.dialog = el;
                }}/>}
            <Col
                className="clickable-list-item serial-no"
                sm={1} xs={1}
            >
                {/* {this.loggedInUserInfo.Access_Level === 'L2' && <span style={{paddingRight: '20px'}}
                    onClick={this.handleUserPasswordUpdate}
                    title={`Click to Update password of user ${instance?.props.First_Name}`}
                    className="delete-details-button">
                    <i className="fa fa-key" aria-hidden="true"/>
                </span> } */}
                {index}
            </Col>
            <Col 
                onClick={() => history?.push(`/users/${instance?.props.id}`)}
                className="clickable-list-item serial-no"
                sm={2} xs={2}
            >   
                {instance?.props.UserId}
            </Col>
            <Col
                onClick={() => history?.push(`/users/${instance?.props.id}`)}
                className="clickable-list-item" xs={3}
                sm={3}
            >
                {instance?.props.First_Name}
            </Col>
            <Col
                onClick={() => history?.push(`/users/${instance?.props.id}`)}
                className="clickable-list-item" xs={3}
                sm={2}
            >
                {instance?.props.Last_Name}
            </Col>
            <Col
                onClick={() => history?.push(`/profile/${instance?.props.id}`)}
                className="clickable-list-item" xs={2}
                sm={2}
            >
                {instance?.props.Access_Level}
            </Col>
            <Col
                onClick={() => history?.push(`/profile/${instance?.props.id}`)}
                className="clickable-list-item" xs={2}
                sm={2}
            >
                {instance?.props.Active}
            </Col>
        </Row>;
    }
}

export function mapStateToProps(state) {
    return {
         
    };
}

export const UserListItem = withRouter(connect< IUserListItemProps, any, any>(mapStateToProps)(UserListItemImpl as any));
