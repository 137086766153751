import * as React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import './commonReportDetailer.scss';
import {format} from 'date-fns';
import {Img} from '../../reusableComponents/ImageGallery/Img';
import {showCarousel} from '../../../actions/miscellaneousActions';
import {MarkAsCompletedPopOver} from '../../reusableComponents/MarkAsCompletePopOver';
import {saveFlagCompletions} from '../../../services/dailyFormService';
import {calculateActualTitleDate} from '../../../utils/generalUtils';
import loadingImage from '../../reusableComponents/DetailsPageWrapper/signLoading.gif';
import textThumb from '../../../images/text-thumb.png';
import videoThumb from '../../../images/video-thumb.png';
import excelThumb from '../../../images/excel-thumb.jpg';
import wordThumb from '../../../images/word-thumb.png';
import pdfThumb from '../../../images/pdf-thumb.png'; 
import pptThumb from '../../../images/ppt-thumb.jpg';

export interface ICommonReportDetailerState {
    formData: any;
    signBase64: string;
}

export interface ICommonReportDetailerProps {
    formData: any;
    onFlagUpdate: () => void;
    onopenInNewViewTab?: Function;

}

export class CommonReportDetailerImpl extends React.Component<ICommonReportDetailerProps, ICommonReportDetailerState> {

    reportsMetaData = JSON.parse(localStorage.getItem('reportsMetaData') || '{}');

    constructor(props: ICommonReportDetailerProps) {
        super(props);
        this.state = {
            signBase64: '',
            formData: props.formData || {}
        };
    }

    componentWillReceiveProps(nextProps) {
        this.forceUpdate();
        this.setState({
            signBase64: '',
            formData: nextProps.formData || {}
        });
    }

    renderTitles = (quest) => {
        return (
            <div key={quest.questionId} className="field-heading">{quest.question}</div>
        );
    }

    renderSecondaryTitles = (quest) => {
        return (
            <div key={quest.questionId} className="secondary-heading">{quest.question}</div>
        );
    }

    renderHintTitles = (quest: any) => {
        return (
            // <div key={quest.title} className="secondary-heading">{quest.question}</div>
            <div key={quest.title} className="secondary-heading" dangerouslySetInnerHTML={{ __html: quest.question }} />
        );
    }

    renderParagraph = (quest) => {
        let ques = quest.question.split('~')
        return (
            <div className="question-row">
                <div key={quest.questionId} className="field-paragraph"> 
                    {
                        (ques || []).map( (item) => {
                            return <p>{item}</p>
                        })
                    }
                </div> 
            </div>
        )
    }

    handleUpdate = async (comments, date, questionId) => {
        let inspId = this.reportsMetaData.inspectionId ? this.reportsMetaData.inspectionId : this.props.formData.inspectionId;

        await saveFlagCompletions(inspId, questionId, comments, format(new Date(date), 'MM-dd-yyyy'),undefined,'CommonReportDetailer').then((res) => {
            if (this.props.onFlagUpdate) {
                this.props.onFlagUpdate();
            }
        });
    }

    renderFlaggedObjectiveTypeQuestions = (quest, localQuestNo, isSubQuest) => {
        return (<div key={quest.questionId} className="flagged-question-container">
            {!quest.followUpRemark ? <div><MarkAsCompletedPopOver ref="popOver" formSubmittedDate={this.reportsMetaData.submittedDate} questionId={quest.questionId} onUpdate={this.handleUpdate}>
                    <div className="mark-as-complete-col"
                         title="Click to mark this question as Complete">
                        <i className="fa fa-flag incomplete-flag"
                           aria-hidden="true"> </i>
                    </div>
                </MarkAsCompletedPopOver>
                    <div className="question-row">
                        <div className="question flagged-question">
                            {quest.question}
                        </div>
                        <div className="answer">
                            <div>{quest.answer || 'Unanswered'}</div>
                        </div>
                    </div>
                </div> :
                <div className="question-row">
                    <i className="fa fa-check-square-o completed-flag" aria-hidden="true">&nbsp;</i>
                    <div className="question flagged-question">
                        {quest.question}
                    </div>
                    <div className="answer">
                        <div>{quest.answer === 'N/A' ? 'Not Applicable' : quest.answer || 'Unanswered'}</div>
                    </div>
                    <div className="answer">
                        <Label
                            icon={<i className="fa fa-check-circle" aria-hidden="true">&nbsp;</i>}
                            key={1} isFlagged={1}>Updated By:
                        </Label>
                        <Value key={2}>{quest.completedBy || 'Unanswered'}</Value>
                        <span key={6} style={{display: 'block', paddingBottom: '10px'}}/>
                        <Label
                            icon={<i className="fa fa-comments" aria-hidden="true">&nbsp;</i>}
                            key={4} isFlagged={1}>Follow Up Comments:
                        </Label>
                        <Value key={5}>{quest.followUpRemark || 'Unanswered'}</Value>
                        <span key={7} style={{display: 'block', paddingBottom: '10px'}}/>
                        <Label
                            icon={<i className="fa fa-calendar" aria-hidden="true">&nbsp;</i>}
                            key={8} isFlagged={1}>Date Updated:
                        </Label>
                        <Value key={9}>{format(new Date(quest.followUpDate), 'MM-dd-yyyy') || 'Unanswered'}</Value>
                        <span key={10} style={{display: 'block', paddingBottom: '10px'}}/>
                    </div>
                </div>
            }

            {/* {quest.subQuestions && quest.subQuestions.length > 0 && quest.answer === quest.answertoSQ && quest.subQuestions.map((subQuest, index) => { */}
            {quest.subQuestions && quest.subQuestions.length > 0 && quest.subQuestions.map((subQuest, index) => {
                    if(subQuest.answerSelected===quest.answer) {
                        return (<div key={subQuest.questionId} className={'question-row'}>{this.generateQuestionAnswer(subQuest, localQuestNo + '.' + (index + 1), true)}</div>);
                    }
            })}
        </div>);
    }

    renderObjectiveTypeQuestions = (quest, localQuestNo, isSubQuest) => { 
        return (
            <React.Fragment key={quest.questionId}>
                <div className="question-row">
                    <div className="question">
                        {quest.question}
                    </div>
                    <div className="answer">
                        <div>{quest.answer === 'N/A' ? 'Not Applicable' : quest.answer || 'Unanswered'}</div>
                    </div>
                </div>
                {/* {quest.subQuestions && quest.subQuestions.length > 0 && quest.answer === quest.answertoSQ && quest.subQuestions.map((subQuest, index) => {
                        return (<div key={Date.now()} className={'question-row'}>{this.generateQuestionAnswer(subQuest, localQuestNo + '.' + (index + 1), true)}</div>);
                })} */}
                {quest.subQuestions && quest.subQuestions.length > 0 && quest.subQuestions.map((subQuest, index) => {
                    if(subQuest.answerSelected===quest.answer) {
                        return (<div key={Date.now()} className={'question-row'}>{this.generateQuestionAnswer(subQuest, localQuestNo + '.' + (index + 1), true)}</div>);
                    }
                })}
            </React.Fragment>
        );
    }

    renderComments = (quest, questNo, isSubQuest) => {
        return <div className="question-row" key={quest.questionId}>
            <div className={'question'}>
                {quest.question}
            </div>
            <div className={'answer'}>{quest.answer || 'Unanswered'}</div>
        </div>;
    }

    renderDate = (quest, questNo, isSubQuest) => {
        return (
            <div className="question-row" key={quest.questionId}>
                <div className="question">
                    {quest.question}
                </div>
                <div className="answer">{quest.answer ? format(new Date(quest.answer), 'MM-dd-yyyy') : 'Unanswered'}</div>
            </div>
        );
    }
    renderTime = (quest, questNo, isSubQuest) => {
        return (
            <div className="question-row" key={quest.questionId}>
                <div className="question">
                    {quest.question}
                </div>
                <div className="answer">{quest.answer ? quest.answer : 'Unanswered'}</div>
            </div>
        );
    }

    generateQuestionAnswer = (quest, questNo, isSubQuest?: boolean) => {
        if (quest.questionType) {
            quest.questionType = quest.questionType.trim();
        }
        switch (quest.questionType) {
            case 'Title': {
                return this.renderTitles(quest);
            }
            case 'Subtitle': {
                return this.renderSecondaryTitles(quest);
            }            
            case 'Hint': {
                return this.renderHintTitles(quest);
            }
            case 'Paragraph': {
                return this.renderParagraph(quest);
            }
            case 'ObjectiveType': {
                // if (quest.isFlagged && (quest.followUpOn === quest.answer)) {
                if (quest.isFlagged && quest.answer) {
                
                    return this.renderFlaggedObjectiveTypeQuestions(quest, questNo, isSubQuest);
                } else {
                    return this.renderObjectiveTypeQuestions(quest, questNo, isSubQuest);
                }
            }
            case 'Text':
            case 'Comments': {
                return this.renderComments(quest, questNo, isSubQuest);
            }
            case 'Date': {
                return this.renderDate(quest, questNo, isSubQuest);
            }
            case 'Time':{
                return this.renderTime(quest, questNo, isSubQuest);
            }

            default: {
                return <span key={Date.now()}/>;
            }
        }
    }

    renderDeclaration = () => {
        const {ImageUrls, SignatureUrls, isDeclarationRequired} = this.props.formData; 
        const {onopenInNewViewTab} = this.props;

        return <div className="declarations" key={Date.now()+Math.random() }>
            <hr className="line-break"/>
            <div className="images-container">
                <div className="image-label">Attachments :</div>
                <div className="images-list">
                    {ImageUrls && ImageUrls.length > 0 ? ImageUrls.map((url, index) => {

                        let fileDet = url.fileName.split('.');  
                        const fileExtension = fileDet[fileDet.length - 1];
                        let imageThumb = '';
                        let fileFormat = 'NotPDF';
                        // let tooltip = fileSplit[0].split('~~')[0] + '.' + fileSplit[fileSplit.length-1];
                        switch(fileExtension.toLowerCase()) {
                            case 'pdf': imageThumb = pdfThumb; fileFormat = 'PDF'; break;
                            case 'doc': case 'docx': imageThumb = wordThumb; break; 
                            case 'xlx': case 'xls': case 'csv': case 'xlsx': imageThumb = excelThumb; break; 
                            case 'txt': imageThumb = textThumb; break;
                            case 'mp4': imageThumb = videoThumb; break; 
                            case 'pptx': case 'ppt': imageThumb = pptThumb; break;
                            case 'png': case 'jpg': case 'jpeg': /* case 'JPG': case 'PNG': case 'JPEG': */ imageThumb = url.imageURL; break;
                            // default: imageThumb = ''; this.invalidFile++; break; 
                            default: imageThumb = ''; break; 
                        } 
                        return <div
                            key={index}
                            title="Click to view in full screen mode."
                            className="image">
                                {fileFormat === "PDF" ?
                                // onClick={() => this.handlePdfView(url.imageURL)}
                                    <span onClick={() => {  onopenInNewViewTab && onopenInNewViewTab(url.imageURL)} }>
                                    <Img src={imageThumb} onClick={() => showCarousel(url.imageURL)} width="100px"  height="100px"/></span>
                                : 
                                    <a href={url.imageURL} target="_blank" title="Click to view this file" aria-hidden="true" >
                                    <Img src={imageThumb} onClick={() => showCarousel(url.imageURL)} width="100px"  height="100px"/></a>
                                }
                            {/* <a href={url.imageURL} target="_blank" title="Click to view this file" aria-hidden="true" >
                            <Img src={imageThumb} onClick={() => showCarousel(url.imageURL)} width="100px"  height="100px"/></a> */}
                        </div>;
                    }) : <div
                        style={{fontSize: '16px', padding: '2vh 0', color: 'gray'}}>
                        No Attachments to this form.
                    </div>
                    }
                </div>
            </div>
            {(isDeclarationRequired) && <div className="declaration-text">
                I certify under penalty of law that this document and all attachments were
                prepared under my direction or supervision in accordance with a system designed to
                assure that qualified personnel properly gathered and evaluated the information
                submitted. Based on my inquiry of the person or persons who manage the system, or those
                persons directly responsible for gathering the information, the information submitted
                is, to the best of my knowledge and belief, true, accurate, and complete. I am
                aware that there are significant penalties for submitting false information,
                including the possibility of fine and imprisonment for knowing violations.
            </div>}
            {(isDeclarationRequired) && <div className="uploaded-signature-container">
                <div className="image-label">Attached Signature:</div>
                {SignatureUrls && SignatureUrls.length > 0 ?
                    <div className="signature-image">
                        <Img src={SignatureUrls} onClick={() => showCarousel(SignatureUrls)}/>
                    </div> :
                    <div className="no-signature">No Signature Attached</div>
                }
            </div>}
        </div>;
    }

    render() {
        const {questionAndAnswer, submittedBy, submittedDate, plantId, updatedBy, PsName, lastUpdatedDate, isPermanent} = this.props.formData;
        const {inspectionName, frequency, actualDate, facilityType, permitType} = this.reportsMetaData;
        return <div style={{padding: '20px'}} id="details-page" className="reports-page-container">
            <div className="report-title">{`${inspectionName} - ${calculateActualTitleDate(frequency, actualDate)}`}</div>
            <Row className="print-submitted-data pp-printable-data">
                <Col className="metadata-set"><span className="question">Plant ID:</span><span className="answer">{plantId}</span></Col>
                <Col className="metadata-set"><span className="question">Facility Type:</span><span className="answer">{facilityType}</span></Col>
                <Col className="metadata-set"><span className="question">Permit Type:</span><span className="answer">{permitType}</span></Col>
                <Col className="metadata-set"><span className="question">Permanent:</span><span className="answer">{isPermanent}</span></Col>
                <Col className="metadata-set"><span className="question">Outfall:</span><span className="answer">{PsName || 'NA'}</span></Col>
                <Col className="metadata-set"><span className="question">Submitted:</span><span className="answer">{submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col className="metadata-set"><span className="question">Submitted By:</span><span className="answer">{submittedBy || 'NA'}</span></Col>
                <Col className="metadata-set"><span className="question">Last Updated:</span><span className="answer">{lastUpdatedDate ? format(new Date(lastUpdatedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col className="metadata-set"><span className="question">Updated By:</span><span className="answer">{updatedBy || 'NA'}</span></Col>
            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Plant ID:</span><span className="answer">{plantId}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Facility Type:</span><span className="answer">{facilityType}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Outfall:</span><span className="answer">{PsName || 'NA'}</span></Col>

            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Permit Type:</span><span className="answer">{permitType}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Submitted:</span><span className="answer">{submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Submitted By:</span><span className="answer">{submittedBy || 'NA'}</span></Col>
            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Permanent:</span><span className="answer">{isPermanent}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Last Updated:</span><span className="answer">{lastUpdatedDate ? format(new Date(lastUpdatedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Updated By:</span><span className="answer">{updatedBy || 'NA'}</span></Col>
            </Row>
            <hr className="line-break"/>
            <Row className="question-answer-list">
                {/*<PerfectScrollbar>*/}
                {
                    questionAndAnswer && questionAndAnswer.length > 0 && questionAndAnswer.map((qa, index) => {
                        return this.generateQuestionAnswer(qa, index + 1, false);
                    })
                }
                {/*</PerfectScrollbar>*/}
            </Row>
            <Row className="declarations">
                {this.renderDeclaration()}
            </Row>
        </div>;
    }
}

export function mapStateToProps(state, ownProps) {
    return {};
}

export const CommonReportDetailer = connect<{}, ICommonReportDetailerState, ICommonReportDetailerProps>(mapStateToProps)(CommonReportDetailerImpl as any);

const Label = ({children, isFlagged, icon}) => {
    return <div
        style={{fontSize: '18px', color: `${isFlagged ? '#A4BF43' : 'gray'}`}}
    >
        {isFlagged ?
            icon
            : ''}
        {children}
    </div>;
};

const Value = ({children}) => {
    return <div style={{fontSize: '18px', marginBottom: '0.5vh'}}>{children}</div>;
};
