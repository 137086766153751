import * as React from 'react';
import {BaseModel} from './BaseModel';

interface IOutFalls {
    Outfall1: boolean;
    Outfall2: boolean;
    Outfall3: boolean;
    Outfall4: boolean;
    Outfall5: boolean;
    Outfall6: boolean;
}

interface IInspections {
    DailyForm: boolean;
    PlantPreventive: boolean;
    MonthlySPCC: boolean;
    MonthlySpillLogs: boolean;
    MonthlySW: boolean;
    MonthlyUSTRect: boolean;
    MonthlyUSTSpill: boolean;
    QTRVisual: boolean;
    ShopInspection: boolean;
}

interface IPlantModelProps {
    id: string;
    plantId: string;
    plantName: string;
    orgId: string;
    plantRegion: string;
    plantRegionName?: string;
    street: string;
    city: string;
    state: string;
    zip?: number;
    Active?: number;
    TotalCount?: number;
    zipCode?: number;
    facilityType?: string;
    country: string;
    coords?: any;
    inspections?: object;
    shapesList?: Array<any>;
    users?: string[];
    outfallsList?: Array<any>;
    coordinatesPointerList?: Array<any>;
    userdetailsList?: Array<any>;
}

export class PlantModel extends BaseModel<IPlantModelProps> {
    static resource = 'plant';

    constructor(props: IPlantModelProps) {
        super(props, 'plant');
    }
}
interface IUserModelProps {
    userInstances: UserModel[];
    // history?: IHistory;
}
export class UserModel extends BaseModel<IPlantModelProps> {
    static resource = 'user';

    constructor(props: IPlantModelProps) {
        super(props, 'user');
    }
}
