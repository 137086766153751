import * as React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import './ArgosHousekeepingReportDetailer.scss';
import {format} from 'date-fns';
import {Img} from '../../reusableComponents/ImageGallery/Img';
import {showCarousel} from '../../../actions/miscellaneousActions';
import {MarkAsCompletedPopOver} from '../../reusableComponents/MarkAsCompletePopOver';
import {saveFlagCompletions} from '../../../services/dailyFormService';
import {calculateActualTitleDate, calculateCurrentWeekOfMonth, convertTime, getAnswerWithFlag} from '../../../utils/generalUtils';
import loadingImage from '../../reusableComponents/DetailsPageWrapper/signLoading.gif';
import textThumb from '../../../images/text-thumb.png';
import videoThumb from '../../../images/video-thumb.png';
import excelThumb from '../../../images/excel-thumb.jpg';
import wordThumb from '../../../images/word-thumb.png';
import pdfThumb from '../../../images/pdf-thumb.png'; 
import pptThumb from '../../../images/ppt-thumb.jpg';
import { getPriorFormDetails } from '../../../services/formService';
import { HOUSEKEEPINGSCORES } from '../../../constants/generals';

export interface IArgosHousekeepingReportDetailerState {
    formData: any;
    signBase64: string; 
    priorHousekeepingDetails: any;
}

export interface IArgosHousekeepingReportDetailerProps {
    formData: any;
    onopenInNewViewTab: Function;
    onFlagUpdate: () => void;
}

export class ArgosHousekeepingReportDetailerImpl extends React.Component<IArgosHousekeepingReportDetailerProps, IArgosHousekeepingReportDetailerState> {

    reportsMetaData = JSON.parse(localStorage.getItem('reportsMetaData') || '{}');
    listOfNWQuestions: Array<any> = [];
    questionCount = 0;

    constructor(props: IArgosHousekeepingReportDetailerProps) {
        super(props);
        this.state = {
            signBase64: '',
            formData: props.formData || {}, priorHousekeepingDetails:[]
        }; 
        if(this.reportsMetaData.parentInspectionName === 'Housekeeping Plant80' || this.reportsMetaData.parentInspectionName === 'Housekeeping Shop80') {
            this.getPriorFormDetails();
        }
        
    }

    componentWillReceiveProps(nextProps) {
        this.forceUpdate();
        this.setState({
            signBase64: '',
            formData: nextProps.formData || {}
        });
        /* this.getPriorFormDetails() */
    }
    async getPriorFormDetails() { 
        let priorHousekeepingDetails = await getPriorFormDetails(this.reportsMetaData.plantUID, this.reportsMetaData.FormId, this.reportsMetaData.actualDate);
        this.setState({ priorHousekeepingDetails });
    }
 
    renderReports() {
        const { parentInspectionName } = this.reportsMetaData;
        switch(parentInspectionName) {
            case 'Housekeeping Plant80':
            case 'Housekeeping Shop80':
                return this.renderHousekeepingDetails();  
            case 'Daily-Weekly Check (All)80':
            case 'Daily-Weekly Check (additional)80':
                return this.renderDailyWeeklyCheckDetails(); 
            case 'Monthly Check (All)80':
                return this.renderMonthlyCheckDetails();   
            case 'Yearly Check80':
                return this.renderYearlyCheckDetails();   
        }
    } 
    renderMonthlyCheckDetails() { 
        const {questionAndAnswer, submittedBy, frequency, submittedDate, plantId, plantName, updatedBy, PsName, lastUpdatedDate, isPermanent, inspectionDate} = this.props.formData;  
        let actualDate = calculateActualTitleDate(frequency, inspectionDate) 
        return (
            <div className='monthly-report-table-content' style={{paddingTop: '15px'}}>
                <table className='monthly-table'> 
                    <thead></thead>
                    <tbody> 
                        <tr>
                            <th className='checklist-question'>PLANT MAINTENANCE CHECK LISTS</th> 
                            <th className='record-date' rowSpan={3}>Record Date</th>
                            <th className='completed-comment' rowSpan={3} >REPAIR NEEDED COMMENT</th>
                            <th className='completed-date' rowSpan={3}>Repair Date</th>
                        </tr>
                        <tr>
                            <th>Date: {actualDate}</th>
                        </tr>
                        <tr>
                            <th>Plant Manager sign off: {submittedBy}</th>
                        </tr>
                         
                    </tbody>
                    { questionAndAnswer.map((infoData: any, index: any) => { 
                        if( infoData.questionType==='Subtitle') {
                            return this.renderWeeklyReportSubtitle(index)
                        }
                        if( infoData.questionType==='Date' && infoData.question!=='Repair Complete Date') {  
                            return this.renderMonthlyAnswers(index)
                        }  
                        if( infoData.question==='Comments') {
                            return this.renderComments(infoData)
                        }
                    }) } 
                </table>
            </div>
        );
    }
    renderComments = (infoData) => {
        return <div className="question-row" key={infoData.questionId}>
            <div className={'question'}>
                {infoData.question}
            </div>
            <div className={'answer'}>{infoData.answer || ''}</div>
        </div>;
    } 
    renderMonthlyAnswers(index) {
        const {questionAndAnswer } = this.props.formData;
        let answer = questionAndAnswer[index]; 
        let completedComments = questionAndAnswer[index+1];
        let completedDate = questionAndAnswer[index+2];
        let tick = <i className="fa fa-check-circle" aria-hidden="true"></i>;
        return (
            <tr>
                <td className='question' >{answer.question}</td> 
                <td className='answer-record-date' >{answer.answer}</td> 
                <td className='repair-comment' >{completedComments.answer}</td>
                <td className='repair-completed-date' >{completedDate.answer}</td>
            </tr>
        ); 
    }
    renderYearlyCheckDetails() { 
        const {questionAndAnswer, submittedBy, frequency, submittedDate, plantId, plantName, updatedBy, PsName, lastUpdatedDate, isPermanent, inspectionDate} = this.props.formData; 
        return (
            <div className='yearly-report-table-content' style={{paddingTop: '15px'}}>
                <table className='yearly-table'> 
                    <thead></thead>
                    <tbody> 
                        <tr>
                            <th className='checklist-question'>PLANT MAINTENANCE CHECK LISTS</th> 
                            <th className='completed-comment' rowSpan={2} colSpan={3}>Record Date completed</th>
                            <th className='completed-date' rowSpan={3} >REPAIR NEEDED COMMENT</th>
                            <th className='completed-date' rowSpan={3}>Repair Date</th>
                        </tr>
                        <tr>
                            <th>Plant Manager sign off: {submittedBy}</th>
                        </tr>
                        <tr>
                            <th>Repair where required</th>
                            <th>6</th>
                            <th>12</th>
                            <th>Annual</th>
                        </tr>
                        { questionAndAnswer.map((infoData: any, index: any) => { 
                            if( infoData.questionType==='Subtitle') {
                                return this.renderWeeklyReportSubtitle(index)
                            }
                            if( infoData.questionType==='ObjectiveType') {  
                                return this.renderYearlyReportObjectiveType(index)
                            }  
                            if( infoData.question==='Comments') {
                                return this.renderYearlyComments(infoData)
                            }
                        }) } 
                    </tbody>
                </table>
            </div>
        );
    }
    renderYearlyReportObjectiveType(index) {
        const {questionAndAnswer } = this.props.formData;
        let answer = questionAndAnswer[index];
        let completedComments = questionAndAnswer[index+1];
        let completedDate = questionAndAnswer[index+2];

        completedComments = completedComments.answer.split('~');
        completedDate = completedDate.answer.split('~');
        let month = ['6 Month', '12 Month']
        let tick = <i className="fa fa-check-circle" aria-hidden="true"></i>;
        return (
            <tr>
                <td className='question' >{answer.question}</td>
                <td className='answer-monthly' >{answer.answer.includes('6 Month')? tick: '' }</td>
                <td className='answer-monthly' >{answer.answer.includes('12 Month')? tick: ''}</td>
                <td className='answer-annually' >{answer.answer.includes('Annual')? tick: ''}</td> 
                {/* <td className='repair-comment' >{completedComments.answer}</td>
                <td className='repair-completed-date' >{completedDate.answer}</td> */}
                 <td className='repair-comment' >{completedComments.length>1 ? completedComments.map( (item, index) => { 
                    return item!=='' && <><span className='day-title'>{month[index]} - </span>{item},</>
                    }): (completedComments[0]!==''?<><span className='day-title'>Annual</span> - {completedComments[0]}</>:'')
                }</td>
                <td className='repair-completed-date' >{completedDate.length>1 ? completedDate.map( (item, index) => { 
                    return item!=='' && <><span className='day-title'>{month[index]}</span> - {item},</>
                    }) :  (completedDate[0]!==''?<><span className='day-title'>Annual</span> - {completedDate[0]}</>:'')
                }</td>
            </tr>
        );
    } 
    renderYearlyComments = (infoData) => {
        let answers = infoData.answer.split('~');  
        let months = ['6 Month', '12 Month'] 
        return <tr className="question-row" key={infoData.questionId}>
            <td colSpan={10}>
                <div className={'question'}>
                    {infoData.question}
                </div>
                <div className={'answer'}>  {answers.length>0 ? answers.map( (item, index) => { 
                        return item!=='' && <><span className='day-title'>{months[index]}</span> - {item},</>
                        }) :  ''
                    } 
                </div>
            </td>
        </tr>;
    }
    renderDailyWeeklyCheckDetails() { 
        const {questionAndAnswer, submittedBy, frequency, submittedDate, plantId, plantName, updatedBy, PsName, lastUpdatedDate, isPermanent, inspectionDate} = this.props.formData; 
        const { currWeekOfMonth, currWeekOfMonthWithSuffix, year, monthInChar } = calculateCurrentWeekOfMonth(inspectionDate);
        return (
            <div className='weekly-report-table-content' style={{paddingTop: '15px'}}>
                <table className='weekly-table'> 
                    <thead></thead>
                    <tbody> 
                        <tr>
                            <th className='checklist-question'>PLANT MAINTENANCE CHECK LISTS</th>
                            <th className='completed-on' colSpan={7}>Initial when completed</th>
                            <th className='completed-comment' rowSpan={3}>Repair Needed Comment</th>
                            <th className='completed-date' rowSpan={3}>Repair Complete Date</th>
                        </tr>
                        <tr>
                            <th>DATE:  {monthInChar}  {currWeekOfMonthWithSuffix} Week</th>
                            <th colSpan={6}> DAILY </th> 
                            <th rowSpan={2}>Weekly</th>
                        </tr>
                        <tr>
                            <th>Plant Manager sign off: {submittedBy}</th>  
                            <th>M</th>
                            <th>T</th>
                            <th>W</th>
                            <th>T</th>
                            <th>F</th>
                            <th>S</th>
                        </tr> 
                        { questionAndAnswer.map((infoData: any, index: any) => { 
                            if( infoData.questionType==='Subtitle') {
                                return this.renderWeeklyReportSubtitle(index)
                            }
                            if( infoData.questionType==='ObjectiveType') {  
                                return this.renderWeeklyReportObjectiveType(index)
                            }  
                            if( infoData.question==='Comments') {
                                return this.renderWeeklyComments(infoData)
                            }
                        }) } 
                    </tbody>
                </table>
            </div>
        )
    } 
    renderWeeklyComments = (infoData) => {
        let answers = infoData.answer.split('~');  
        let days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        return <tr className="question-row" key={infoData.questionId}>
            <td colSpan={10}>
                <div className={'question'}>
                    {infoData.question}
                </div>
                <div className={'answer'}>  {answers.length>0 ? answers.map( (item, index) => { 
                        return item!=='' && <><span className='day-title'>{days[index]}</span> - {item},</>
                        }) :  ''
                    } 
                </div>
            </td>
        </tr>;
    } 
    renderWeeklyReportSubtitle(index) {
        const {questionAndAnswer } = this.props.formData;
        return (
            <tr>
                <td className='subtitle' colSpan={10}>{questionAndAnswer[index].question}</td>
            </tr>
        );

    }
    renderWeeklyReportObjectiveType(index) {
        const {questionAndAnswer } = this.props.formData;
        let answer = questionAndAnswer[index];
        let completedComments = questionAndAnswer[index+1];
        let completedDate = questionAndAnswer[index+2];
        completedComments = completedComments.answer.split('~')
        completedDate = completedDate.answer.split('~')
        let tick = <i className="fa fa-check-circle" aria-hidden="true"></i>;
        let days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        return (
            <tr>
                <td className='question' >{answer.question}</td>
                <td className='answer-daily' >{answer.answer.includes('Mon')? tick: ''}</td>
                <td className='answer-daily' >{answer.answer.includes('Tue')? tick: ''}</td>
                <td className='answer-daily' >{answer.answer.includes('Wed')? tick: ''}</td>
                <td className='answer-daily' >{answer.answer.includes('Thu')? tick: ''}</td>
                <td className='answer-daily' >{answer.answer.includes('Fri')? tick: ''}</td>
                <td className='answer-daily' >{answer.answer.includes('Sat')? tick: ''}</td>
                <td className='answer-weekly' >{answer.answer.includes('Weekly')? tick: ''}</td>
                <td className='repair-comment' >{completedComments.length>1 ? completedComments.map( (item, index) => { 
                    return item!=='' && <><span className='day-title'>{days[index]} - </span>{item},</>
                    }): (completedComments[0]!==''?<><span className='day-title'>Weekly</span> - {completedComments[0]}</>:'')
                }</td>
                <td className='repair-completed-date' >{completedDate.length>1 ? completedDate.map( (item, index) => { 
                    return item!=='' && <><span className='day-title'>{days[index]}</span> - {item},</>
                    }) :  (completedDate[0]!==''?<><span className='day-title'>Weekly</span> - {completedDate[0]}</>:'')
                }</td>
            </tr>
        );
    } 
    renderHousekeepingDetails() {  
        const {questionAndAnswer, submittedBy, submittedDate, plantId, plantName, updatedBy, PsName, lastUpdatedDate, isPermanent} = this.props.formData;
        const { parentInspectionName } = this.reportsMetaData;
        const { priorHousekeepingDetails } = this.state; 
        let grandTotal = parentInspectionName==='Housekeeping Plant80'?questionAndAnswer[38]:(parentInspectionName==='Housekeeping Shop80'?questionAndAnswer[29]:0);
        this.questionCount = 0;
        let startIndex = parentInspectionName==='Housekeeping Plant80'?0:(parentInspectionName==='Housekeeping Shop80'?0:0);
        return (
            <div className='argos-housekeeping-report-table-content' style={{paddingTop: '15px'}}>
                <Row className='housekeeping-header'>
                    <Col sm={6} md={6} lg={6}>
                        <div className="prior-score"><span className="question">Plant Number - Name:  </span><span className="prior-answer">{plantId} - {plantName}</span></div>
                    </Col>
                </Row>
               
                <Row className='housekeeping-header'>
                    <Col sm={6} md={6} lg={6}>
                        <div className="prior-score"> {questionAndAnswer[0].question}: <span className="prior-answer">{questionAndAnswer[0].answer} </span></div> 
                    </Col>
                </Row>
                {/* { #Not used
                    parentInspectionName==='Housekeeping Shop80' && 
                    <Row className='housekeeping-header'>
                        <Col sm={6} md={6} lg={6}>
                            <div className="prior-score"> {questionAndAnswer[1].question}: <span className="prior-answer">{questionAndAnswer[1].answer} </span></div> 
                        </Col>
                    </Row>
                } */}
                <Row className='housekeeping-header'>
                    <Col sm={6} md={6} lg={6}>
                        <div className="prior-score"> Prior Scorecard Date and Score: <span className="prior-answer">{priorHousekeepingDetails && Object.keys(priorHousekeepingDetails).length >0?`${format(new Date(priorHousekeepingDetails['date']), 'MM/dd/yyyy') } & ${priorHousekeepingDetails['totalScore']}%`: `NILL`} </span></div>
                    </Col>
                    <Col sm={6} md={6} lg={6}>
                        <div key="housekeeping-header-total-score" className="housekeeping-header-total-score"> <span className='housekeeping-header-total-score-title'>Grand Total: </span><span className='housekeeping-header-total-score-answer'>{grandTotal && grandTotal.answer || 0} %</span></div>
                    </Col>
                </Row>
                <table className='housekeeping-table'> 
                    <thead></thead>
                    <tbody>  
                        { questionAndAnswer.map((infoData: any, index: any) => {
                            if(index>startIndex) {
                                if( infoData.questionType==='Title') {
                                    return this.renderHouseKeepingBlockHeader(index)
                                }
                                if( infoData.questionType==='ObjectiveType') {  
                                    return this.renderHouseKeepingAnswers(index)
                                }
                                if( infoData.questionType==='BlockScore') {  
                                    return this.renderHouseKeepingBlockScore(index)
                                }
                                if( infoData.questionType==='TotalScore') {  
                                    return this.renderHouseKeepingFinalScore(index)
                                }
                                if( infoData.questionType==='Comments') {  
                                    return this.renderHouseKeepingOverallComments(infoData)
                                }
                            } 
                        }) } 
                    </tbody>
                </table> 
                {this.calcHousekeepingNotes()} 
            </div>
        );
    }
    renderHouseKeepingOverallComments(infoData) {
        return <tr className="question-row" key={infoData.questionId}>
        <td colSpan={10}>
            <div className={'question'}>
                {infoData.question}
            </div>
            <div className={'answer'}>
                {infoData.answer}
            </div>
        </td>
    </tr>;
    }
    calcHousekeepingNotes = () => {
        if(this.reportsMetaData.parentInspectionName && (this.reportsMetaData.parentInspectionName === 'Housekeeping Plant80' || this.reportsMetaData.parentInspectionName === 'Housekeeping Shop80') ) {  
            const { questionAndAnswer } = this.props.formData;
            let housekeepingNotes: any = [];
            for(var key in questionAndAnswer) { 
                let question = questionAndAnswer[key]; 
                if(question['questionType'] === 'ObjectiveType') {
                    if(question['answer'] && question['answer']!=='N/A') {
                        let quesScore = HOUSEKEEPINGSCORES.indexOf(question['answer'])+1;
                        if(quesScore<4) {
                            housekeepingNotes.push([question['question'], question['answer']])
                        }
                    }
                }
            }  
            return (<>
                <div className='argos-housekeeping-report-notes '>
                    <div className='housekeeping-notes' ><span className="notes-heading">Notes:</span> {`(List all items scored =<3 )`}</div> 
                    <div>
                        { housekeepingNotes.length>0 ?
                            <ul>
                                { housekeepingNotes.map((infoData, index) => {
                                    return <li key={index}>{`${infoData[0]} - `}<span className='housekeepingNotesScore'>{`${infoData[1]}`}</span></li>;
                                }) }
                            </ul> :
                            <div className='no-notes'>No items found</div>
                        }
                    </div>
    
                </div>
            </>)

        }
    }
    renderHouseKeepingFinalScore(index) {  
        const { questionAndAnswer, submittedBy } = this.props.formData;
        return (
            <> <tr>
                <td className="table-col-inspector-title" colSpan={2}>Inspector Name: <span className="table-col-inspector-answer">{submittedBy}</span></td> 
                <td className="table-col-finalscore-question" colSpan={6} >{questionAndAnswer[index].question}</td>
                <td className="table-col-finalscore-answer" colSpan={3}><span>{questionAndAnswer[index].answer} %</span></td>
            </tr> </>
        );
    }
    renderHouseKeepingBlockScore(index) { 
        const {questionAndAnswer } = this.props.formData;
        return (
            <> <tr>
                <td className="table-col-blockscore-question" colSpan={8}>{questionAndAnswer[index].question}</td>
                <td className="table-col-blockscore-answer">{questionAndAnswer[index].answer}</td>
            </tr> </>
        );
    }
    renderHouseKeepingBlockHeader(index) { 
        const {questionAndAnswer } = this.props.formData;
        return (
            <><tr>
                <th className="table-col-sno"></th>
                <th className="table-col-question"></th>
                <th className="table-col-ans1">UNACCEPTABLE</th>
                <th className="table-col-ans2">POOR</th>
                <th className="table-col-ans3">FAIR</th>
                <th className="table-col-ans4">GOOD</th>
                <th className="table-col-ans5">EXCELLENT</th>
                <th className="table-col-ansna" rowSpan={2}>N/A</th>
                <th className="table-col-score" rowSpan={2}>Score</th>
                <th className="table-col-standard"></th>  
            </tr>
            <tr>
                <th className="table-col-sno">{questionAndAnswer[index].question}</th>
                <th className="table-col-question">{questionAndAnswer[index].questionHint}</th>
                <th className="table-col-ans1">1</th>
                <th className="table-col-ans2">2</th>
                <th className="table-col-ans3">3</th>
                <th className="table-col-ans4">4</th>
                <th className="table-col-ans5">5</th> 
                <th className="table-col-standard">STANDARD - Explained</th>  
            </tr> </>
        )
    }
    renderHouseKeepingAnswers(index) {
        const {questionAndAnswer } = this.props.formData;
        let answer = questionAndAnswer[index].answer; 
        this.getQuestionCount(questionAndAnswer[index])
        return (
            <tr>
                <td className="table-col-sno">{(this.questionCount)}</td>
                <td className="table-col-question">{questionAndAnswer[index].question}</td>
                <td className="table-col-ans1">{answer==='Unacceptable'?<i className="fa fa-check-circle" aria-hidden="true"></i>:''}</td>
                <td className="table-col-ans2">{answer==='Poor'?<i className="fa fa-check-circle" aria-hidden="true"></i>:''}</td>
                <td className="table-col-ans3">{answer==='Fair'?<i className="fa fa-check-circle" aria-hidden="true"></i>:''}</td>
                <td className="table-col-ans4">{answer==='Good'?<i className="fa fa-check-circle" aria-hidden="true"></i>:''}</td>
                <td className="table-col-ans5">{answer==='Excellent'?<i className="fa fa-check-circle" aria-hidden="true"></i>:''}</td>  
                <td className="table-col-ansna">{answer==='N/A'?<i className="fa fa-check-circle" aria-hidden="true"></i>:''}</td>
                <td className="table-col-ansscore">{HOUSEKEEPINGSCORES.indexOf(answer)+1}</td> 
                <td className="table-col-standard">{questionAndAnswer[index].questionHint}</td>  
            </tr>
        )
    }
    getQuestionCount = (questData: any) => {
        return (questData && questData.question && (questData.questionType !== 'Title' && questData.questionType !== 'Subtitle' && questData.questionType !== 'Paragraph' && questData.questionType !== 'BlockScore' && questData.question !== 'Plant Leadperson')) ? this.questionCount += 1 : null;
    }
    renderImages = () => {
        const {ImageUrls, SignatureUrls, isDeclarationRequired} = this.props.formData;
        const {onopenInNewViewTab} = this.props;

        return <div className="declarations" key={Date.now()}>
            <hr className="line-break"/>
            <div className="images-container">
                <div className="image-label">Attachments :</div>
                <div className="images-list">
                    {ImageUrls && ImageUrls.length > 0 ? ImageUrls.map((url, index) => {
                        let fileDet = url.fileName.split('.');  
                        const fileExtension = fileDet[fileDet.length - 1];
                        let imageThumb = '';
                        let fileFormat = 'NotPDF';
                        // let tooltip = fileSplit[0].split('~~')[0] + '.' + fileSplit[fileSplit.length-1];
                        switch(fileExtension.toLowerCase()) {
                            case 'pdf': imageThumb = pdfThumb; fileFormat = 'PDF'; break;
                            case 'doc': case 'docx': imageThumb = wordThumb; break; 
                            case 'xlx': case 'xls': case 'csv': case 'xlsx': imageThumb = excelThumb; break; 
                            case 'txt': imageThumb = textThumb; break;
                            case 'mp4': imageThumb = videoThumb; break; 
                            case 'pptx': case 'ppt': imageThumb = pptThumb; break;
                            case 'png': case 'jpg': case 'jpeg': /* case 'JPG': case 'PNG': case 'JPEG': */ imageThumb = url.imageURL; break;
                            // default: imageThumb = ''; this.invalidFile++; break; 
                            default: imageThumb = ''; break; 
                        } 
                        return <div
                            key={index}
                            title="Click to view in full screen mode."
                            className="image">
                                {fileFormat == "PDF" ?
                                    <span onClick={() => onopenInNewViewTab(url.imageURL)} >
                                    <Img src={imageThumb} onClick={() => showCarousel(url.imageURL)} width="100px"  height="100px"/></span>
                                : 
                                    <a href={url.imageURL} target="_blank" title="Click to view this file" aria-hidden="true" >
                                    <Img src={imageThumb} onClick={() => showCarousel(url.imageURL)} width="100px"  height="100px"/></a>
                                }
                        </div>;
                    }) : <div
                        style={{fontSize: '16px', padding: '2vh 0', color: 'gray'}}>
                        No Attachments to this form.
                    </div>
                    }
                </div>
            </div> 
        </div>;
    }

    renderAnswer(index) { 
        const {questionAndAnswer } = this.props.formData;
        let quest = questionAndAnswer[index];
        let answer = questionAndAnswer[index]['answer'];
        let { followUpLogo, followUpClass, defaultValue } = getAnswerWithFlag(quest) 
        
        return (
            quest.isFlagged && quest.followUpOn===quest.answer ? <div className="flagged-answer"> <MarkAsCompletedPopOver ref="popOver" formSubmittedDate={this.reportsMetaData.submittedDate} questionId={quest.questionId} onUpdate={this.handleFlagAnswerUpdate} defaultValue={defaultValue}>
                    <div className={`${followUpClass} mark-as-complete-col`}
                         title="Click to enter the follow-up comment">
                        <i className={followUpLogo} aria-hidden="true"> </i> {answer}
                    </div> 
            </MarkAsCompletedPopOver> </div> : answer
        )
    }
    handleFlagAnswerUpdate = async (comments, date, questionId) => {
        await saveFlagCompletions(this.reportsMetaData.inspectionId, questionId, comments, format(new Date(date), 'MM-dd-yyyy'),undefined,'ArgosHousekeepingReportDetailer').then((res) => {
            if (this.props.onFlagUpdate) {
                this.props.onFlagUpdate();
            }
        });
    }

    render() {
        const {questionAndAnswer, submittedBy, submittedDate, plantId, updatedBy, PsName, lastUpdatedDate, isPermanent} = this.props.formData;
        const {inspectionName, frequency, actualDate, facilityType, permitType} = this.reportsMetaData;
        return <div style={{padding: '20px'}} id="details-page" className="reports-page-container">
            <div className="report-title">{`${inspectionName} - ${calculateActualTitleDate(frequency, actualDate)}`}</div>
            <div className="pp-printable-data">
                <div className="metadata-set"><span className="question">Plant ID:</span><span className="answer">{plantId}</span></div>
                <div className="metadata-set d-print-none"><span className="question">Facility Type:</span><span className="answer">{facilityType}</span></div>
                <div className="metadata-set d-print-none"><span className="question">Permit Type:</span><span className="answer">{permitType}</span></div>
                <Col className="metadata-set d-print-none"><span className="question">Permanent:</span><span className="answer">{isPermanent}</span></Col>
                <Col className="metadata-set d-print-none"><span className="question">Outfall:</span><span className="answer">{PsName || 'NA'}</span></Col>
                <div className="metadata-set"><span className="question">Submitted:</span><span className="answer">{submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z' ), 'MM-dd-yyyy') : 'NA'}</span></div>
                <div className="metadata-set"><span className="question">Submitted By:</span><span className="answer">{submittedBy || 'NA'}</span></div>
                <Col className="metadata-set d-print-none"><span className="question">Last Updated:</span><span className="answer">{lastUpdatedDate ? format(new Date(lastUpdatedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col className="metadata-set d-print-none"><span className="question">Updated By:</span><span className="answer">{updatedBy || 'NA'}</span></Col>
            </div>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Plant ID:</span><span className="answer">{plantId}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Facility Type:</span><span className="answer">{facilityType}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Outfall:</span><span className="answer">{PsName || 'NA'}</span></Col>

            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Permit Type:</span><span className="answer">{permitType}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Submitted:</span><span className="answer">{submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Submitted By:</span><span className="answer">{submittedBy || 'NA'}</span></Col>
            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Permanent:</span><span className="answer">{isPermanent}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Last Updated:</span><span className="answer">{lastUpdatedDate ? format(new Date(lastUpdatedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Updated By:</span><span className="answer">{updatedBy || 'NA'}</span></Col>
            </Row>
            <Row style={{marginTop:'10px'}} className="argos-housekeeping-report-container">
                {this.renderReports()}
            </Row>
            {this.renderImages()}  
        </div>;
    }
}

export function mapStateToProps(state, ownProps) {
    return {};
}

export const ArgosHousekeepingReportDetailer = connect<{}, IArgosHousekeepingReportDetailerState, IArgosHousekeepingReportDetailerProps>(mapStateToProps)(ArgosHousekeepingReportDetailerImpl as any);
 