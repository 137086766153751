import '../../Reports/reports.scss';

import * as React from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {hideUSTModal} from '../../../actions/modalActions';
import {IDailyForms, IHistory} from '../../../interfaces';
import {PlantModel} from '../../../model/PlantModel';
import {getAllPlantsByRegion} from '../../../services/plantService';
import {BasePage} from '../../reusableComponents/BasePage';
import {FormFooter} from '../../reusableComponents/FormComponents/FormFooter';
import {ListPage} from '../../reusableComponents/ListPage';
import {ListFooter} from './ListFooter';
import {ListItem} from './ListItem';
import { Pagination } from '../../reusableComponents/Pagination';

export interface SummaryReportsProps {
    instances: PlantModel[];
    dailyForms?: IDailyForms;
    showUSTModal: boolean;
    formType: string;
    history?: IHistory;
    userId: string;
    selectedDate: string;
    axiosPreviousToken: any;
    formInstance: PlantModel; 
}

export interface SummaryReportsState {
    isAsc: boolean;
    sortIndex: any;
    searchText: any;
    curPageNo :any;
}

export class SummaryReportsImpl extends React.Component<SummaryReportsProps, SummaryReportsState> {

    constructor(props: SummaryReportsProps) {
        super(props);
        this.state = {
            isAsc: true,
            sortIndex: 'plantId',
            curPageNo : 1,
            searchText: ''

        };
    }

    promise = async () => {
        await getAllPlantsByRegion(this.props.userId);
    }

    handleFooterYes = () => {
        const {formInstance: {props: {plantId, plantRegion}}, selectedDate, formType} = this.props;
        this.props.history?.push(`/monthly-checklist-form/${formType}/${plantId}/${plantRegion}/${selectedDate}`);
        hideUSTModal();
    }

    handleFooterNo = () => {
        const {formInstance: {props: {plantId, plantRegion}}, selectedDate, formType} = this.props;
        this.props.history?.push(`/monthly-checklist-form/${formType}/${plantId}/${plantRegion}/${selectedDate}`);
        hideUSTModal();
    }

    renderModal = () => {
        return <Modal
            show={this.props.showUSTModal}
            onHide={() => hideUSTModal()}
            className="argos-modal"
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title">
                    Select Form Type
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Is this a corrective action follow-up form?
            </Modal.Body>
            <Modal.Footer>
                <FormFooter
                    submitLabel="Yes"
                    cancelLabel="No"
                    onSubmit={this.handleFooterYes}
                    onCancel={this.handleFooterNo}
                />
            </Modal.Footer>
        </Modal>;
    }
    
    handleSort = async (Asc, index, searchText) => {
        await this.setState({
            isAsc: Asc,
            sortIndex: index,
            curPageNo: searchText.length > 0 && this.state.searchText === searchText  ? this.state.curPageNo : 1,
            searchText: searchText
        })
        await getAllPlantsByRegion(this.props.userId, 'All', this.state.isAsc, this.state.sortIndex, this.state.curPageNo, searchText, this.props.axiosPreviousToken);
    }
    handlePagination = async () => {

        let curPageNo = this.state.curPageNo || '1';
        await getAllPlantsByRegion(this.props.userId, 'All', this.state.isAsc, this.state.sortIndex, curPageNo, this.state.searchText, this.props.axiosPreviousToken);

    }

    render() {
        const {instances} = this.props;
        let totalCount = instances[0]?.props.TotalCount ? instances[0]?.props.TotalCount : 20
        let pageno = Math.ceil(totalCount/5 );

        return (
            <BasePage className="list-page-base" pageTitle="Reports Summary">
                {this.renderModal()}
                <ListPage
                    // @ts-ignore
                    identifier="PlantListPage"
                    height="71vh"
                    instances={instances}
                    listItemComponent={ListItem}
                    searchBy={['plantId', 'plantName', 'plantRegionName', 'facilityType', 'State']}
                    instanceOf={'plantInfo'}
                    pageHeadings={['#', 'Plant ID', 'Plant Name', 'Region', 'Facility Type', 'State']}
                    listItemFields={['', 'plantId', 'plantName', 'plantRegionName', 'facilityType', 'State']}
                    promise={this.promise}
                    onHandleSort={this.handleSort}
                    cols={[1, 2, 3, 2, 2, 2]}
                    searchPlaceHolder="Enter plant id, plant name, region, facility type or state to search..."
                    emptyListMessage="No plants found"
                    listFooter={ListFooter}
                />
                <Pagination
                    promise={async (e: any) => {
                        await this.setState({ curPageNo: e});
                        this.handlePagination(); // Call the function inside the async arrow function
                    }}
                    totalCount={pageno}
                    // Model={OIModel as any} 
                    pageNum={this.state.curPageNo}
                    identifier="AllListPage"
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state) {
    const instances = PlantModel.list(state);
    const showUSTModal = state.modal.get('showUSTModal');
    const formType = state.modal.get('formType');
    const formInstance = state.modal.get('formInstance');
    const selectedDate = state.modal.get('selectedDate');
    const userId = state.login.get('UniqueId');
    const axiosPreviousToken = state.miscellaneous.get('axiosPreviousToken');

    return {
        userId, instances, showUSTModal, formType, selectedDate, formInstance, axiosPreviousToken
    };
}

export const SummaryReportsSlider =
    withRouter(connect< SummaryReportsProps, any, any>(mapStateToProps)(SummaryReportsImpl as any));
