import * as React from 'react';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import './FreeseReportDetailer.scss';
import {format} from 'date-fns';
import {Img} from '../../reusableComponents/ImageGallery/Img';
import {showCarousel} from '../../../actions/miscellaneousActions';
import {MarkAsCompletedPopOver} from '../../reusableComponents/MarkAsCompletePopOver';
import {saveFlagCompletions} from '../../../services/dailyFormService';
import {calculateActualTitleDate} from '../../../utils/generalUtils';
import loadingImage from '../../reusableComponents/DetailsPageWrapper/signLoading.gif';
import textThumb from '../../../images/text-thumb.png';
import videoThumb from '../../../images/video-thumb.png';
import excelThumb from '../../../images/excel-thumb.jpg';
import wordThumb from '../../../images/word-thumb.png';
import pdfThumb from '../../../images/pdf-thumb.png'; 
import pptThumb from '../../../images/ppt-thumb.jpg';

export interface IFreeseReportDetailerState {
    formData: any;
    signBase64: string;
}

export interface IFreeseReportDetailerProps {
    formData: any;
    onFlagUpdate: () => void;
    onopenInNewViewTab: Function;
}

export class FreeseReportDetailerImpl extends React.Component<IFreeseReportDetailerProps, IFreeseReportDetailerState> { 
    reportsMetaData = JSON.parse(localStorage.getItem('reportsMetaData') || '{}');
    listOfNWQuestions: Array<any> = [];
    questionCount = 0;

    constructor(props: IFreeseReportDetailerProps) {
        super(props);
        this.state = {
            signBase64: '',
            formData: props.formData || {}
        };
    }

    componentWillReceiveProps(nextProps) {
        this.forceUpdate();
        this.setState({
            signBase64: '',
            formData: nextProps.formData || {}
        });
    }

    renderTitles = (quest) => {
        return (
            <tr key={quest.questionId} className="main-title">
                <td colSpan={4}>{quest.question}</td>
            </tr>
        );
    }

    renderSecondaryTitles = (quest) => {
        return (
            <tr key={quest.questionId} className="secondary-title">
                <td colSpan={4}> {quest.question}</td>
                {/*    <td className="hidden">{quest.question}</td>
                <td className="hidden">{quest.question}</td>
                <td className="hidden" style={{'border-right': '2px solid'}}>{quest.question}</td>*/}
            </tr>
        );
    }

    handleUpdate = async (comments, date, questionId) => {
        let inspId = this.reportsMetaData.inspectionId ? this.reportsMetaData.inspectionId : this.props.formData.inspectionId;

        await saveFlagCompletions(inspId, questionId, comments, format(new Date(date), 'MM-dd-yyyy'),undefined,'FreeseReportDetailer').then((res) => {
            if (this.props.onFlagUpdate) {
                this.props.onFlagUpdate();
            }
        });
    }

    renderFlaggedObjectiveTypeQuestions = (quest, localQuestNo, isSubQuest) => {
        return (<div key={quest.questionId} className="flagged-question-container">
            {!quest.followUpRemark ? <div><MarkAsCompletedPopOver ref="popOver" formSubmittedDate={this.reportsMetaData.submittedDate} questionId={quest.questionId} onUpdate={this.handleUpdate}>
                    <div className="mark-as-complete-col"
                         title="Click to mark this question as Complete">
                        <i className="fa fa-flag incomplete-flag"
                           aria-hidden="true"> </i>
                    </div>
                </MarkAsCompletedPopOver>
                    <div className="question-row">
                        <div className="question flagged-question">
                            {quest.question}
                        </div>
                        <div className="answer">
                            <div>{quest.answer || 'Unanswered'}</div>
                        </div>
                    </div>
                </div> :
                <div className="question-row">
                    <i className="fa fa-check-square-o completed-flag" aria-hidden="true">&nbsp;</i>
                    <div className="question flagged-question">
                        {quest.question}
                    </div>
                    <div className="answer">
                        <div>{quest.answer === 'N/A' ? 'Not Applicable' : quest.answer || 'Unanswered'}</div>
                    </div>
                    <div className="answer">
                        <Label
                            icon={<i className="fa fa-check-circle" aria-hidden="true">&nbsp;</i>}
                            key={1} isFlagged={1}>Updated By:
                        </Label>
                        <Value key={2}>{quest.completedBy || 'Unanswered'}</Value>
                        <span key={6} style={{display: 'block', paddingBottom: '10px'}}/>
                        <Label
                            icon={<i className="fa fa-comments" aria-hidden="true">&nbsp;</i>}
                            key={4} isFlagged={1}>Follow Up Comments:
                        </Label>
                        <Value key={5}>{quest.followUpRemark || 'Unanswered'}</Value>
                        <span key={7} style={{display: 'block', paddingBottom: '10px'}}/>
                        <Label
                            icon={<i className="fa fa-calendar" aria-hidden="true">&nbsp;</i>}
                            key={8} isFlagged={1}>Date Updated:
                        </Label>
                        <Value key={9}>{format(new Date(quest.followUpDate), 'MM-dd-yyyy') || 'Unanswered'}</Value>
                        <span key={10} style={{display: 'block', paddingBottom: '10px'}}/>
                    </div>
                </div>
            }

            {quest.subQuestions && quest.subQuestions.length > 0 && quest.subQuestions.map((subQuest, index) => {
                if(subQuest.answerSelected===quest.answer) {    
                    return (<div key={subQuest.questionId} className={'question-row'}>{this.generateQuestionAnswer(subQuest, localQuestNo + '.' + (index + 1), true)}</div>);
                }
            })}
        </div>);
    }

    getQuestionCount = (questData) => {
        return (questData && questData.question && (questData.questionType !== 'Title' && questData.questionType !== 'Subtitle')) ? this.questionCount += 1 : null;
    }

    renderObjectiveTypeQuestions = (quest, localQuestNo, isSubQuest) => {
        const questionNo = this.getQuestionCount(quest);
        if (quest.answer === 'Needs Work') {
            this.listOfNWQuestions.push(questionNo + ') Needs work - ' + quest.subQuestions[0].answer);
        }
        return (
            <React.Fragment key={quest.questionId}>
                <tr className="question-row">
                    <td className="question">
                        {questionNo + ') ' + quest.question}
                    </td>
                    <td className="answer">
                        <span className="pp-printable-data">{quest.answer === 'Needs Work' ? <span className="fa fa-check"/> : <span/>}</span>
                        <span className="d-print-none">{quest.answer === 'Needs Work' ? quest.subQuestions[0].answer : <span/>}</span>
                    </td>
                    <td>
                        {quest.answer === 'Ok' ? <span className="fa fa-check"/> : <span/>}
                    </td>
                    <td>
                        {quest.answer === 'N/A' ? <span className="fa fa-check"/> : <span/>}
                    </td>
                </tr>
                {/* {quest.subQuestions && quest.subQuestions.length > 0 && quest.answer === quest.answertoSQ && quest.subQuestions.map((subQuest, index) => {
                    {
                        return (<div key={Date.now()} className={'question-row'}>{this.generateQuestionAnswer(subQuest, localQuestNo + '.' + (index + 1), true)}</div>);
                    }
                })}*/}
            </React.Fragment>
        );
    }

    renderComments = (quest, questNo, isSubQuest) => {
        return <div className="question-row" key={quest.questionId}>
            <div className={'question'}>
                {quest.question}
            </div>
            <div className={'answer'}>{quest.answer || 'Unanswered'}</div>
        </div>;
    }

    renderDate = (quest, questNo, isSubQuest) => {
        return (
            <div className="question-row" key={quest.questionId}>
                <div className="question">
                    {quest.question}
                </div>
                <div className="answer">{quest.answer ? format(new Date(quest.answer), 'MM-dd-yyyy') : 'Unanswered'}</div>
            </div>
        );
    }

    generateQuestionAnswer = (quest, questNo, isSubQuest?: boolean) => {
        if (quest.questionType) {
            quest.questionType = quest.questionType.trim();
        }
        switch (quest.questionType) {
            case 'Title': {
                return this.renderTitles(quest);
            }
            case 'Subtitle': case 'Hint': {
                return this.renderSecondaryTitles(quest);
            }
            case 'ObjectiveType': {
                if (quest.isFlagged && (quest.followUpOn === quest.answer)) {
                    // return this.renderFlaggedObjectiveTypeQuestions(quest, questNo, isSubQuest);
                } else {
                    return this.renderObjectiveTypeQuestions(quest, questNo, isSubQuest);
                }
            }
            /*      case 'Comments': {
                  return this.renderComments(quest, questNo, isSubQuest);
              }
              case 'Date': {
                  return this.renderDate(quest, questNo, isSubQuest);
              }
              default: {
                  return <span key={Date.now()}/>;
              }
              */
        }
    }

    renderDeclaration = () => {
        const {ImageUrls, SignatureUrls, isDeclarationRequired} = this.props.formData;
        const {onopenInNewViewTab} = this.props;

        return <div className="declarations" key={Date.now()}>
           {/*  <div className="notes">
                <div>Notes</div>
                <div style={{'border': '2px solid', 'paddingTop': '1%'}}>{this.listOfNWQuestions.join(', ')}</div>
            </div>
            {<hr className="d-print-none__ line-break"/>} */}
            <div className="images-container d-print-none--">
                <div className="image-label">Attachments :</div>
                <div className="images-list">
                    {ImageUrls && ImageUrls.length > 0 ? ImageUrls.map((url, index) => {
                        let fileDet = url.fileName.split('.');  
                        const fileExtension = fileDet[fileDet.length - 1];
                        let imageThumb = '';
                        let fileFormat = 'NotPDF';
                        // let tooltip = fileSplit[0].split('~~')[0] + '.' + fileSplit[fileSplit.length-1];
                        switch(fileExtension.toLowerCase()) {
                            case 'pdf': imageThumb = pdfThumb; fileFormat = 'PDF'; break;
                            case 'doc': case 'docx': imageThumb = wordThumb; break; 
                            case 'xlx': case 'xls': case 'csv': case 'xlsx': imageThumb = excelThumb; break; 
                            case 'txt': imageThumb = textThumb; break;
                            case 'mp4': imageThumb = videoThumb; break; 
                            case 'pptx': case 'ppt': imageThumb = pptThumb; break;
                            case 'png': case 'jpg': case 'jpeg': /* case 'JPG': case 'PNG': case 'JPEG': */ imageThumb = url.imageURL; break;
                            // default: imageThumb = ''; this.invalidFile++; break; 
                            default: imageThumb = ''; break; 
                        } 
                        return <div
                            key={index}
                            title="Click to view in full screen mode."
                            className="image">
                                {fileFormat === "PDF" ?
                                    <span onClick={() => onopenInNewViewTab(url.imageURL)} >
                                    <Img src={imageThumb} onClick={() => showCarousel(url.imageURL)} width="100px"  height="100px"/></span>
                                : 
                                    <a href={url.imageURL} target="_blank" title="Click to view this file" aria-hidden="true" >
                                    <Img src={imageThumb} onClick={() => showCarousel(url.imageURL)} width="100px"  height="100px"/></a>
                                }
                        </div>;
                    }) : <div
                        style={{fontSize: '16px', padding: '2vh 0', color: 'gray'}}>
                        No Attachments to this form.
                    </div>
                    }
                </div>
            </div> 
            {isDeclarationRequired && <div className="uploaded-signature-container d-print-none__">
                <div className="image-label">Collector’s Signature:</div>
                {SignatureUrls && SignatureUrls.length > 0 ?
                    <div className="signature-image">
                        <Img src={SignatureUrls} onClick={() => showCarousel(SignatureUrls)}/>
                    </div> :
                    <div className="no-signature">No Signature Attached</div>
                }
            </div>}
        </div>;
    }
    renderTableData = (span, quesAns) => {
        return <td colSpan={span}><b>{quesAns.question}</b> : {quesAns.answer}</td>
    }

    renderYesNO = (quesAns) => { 
        return <><td colSpan={2} className="objectiveTd" style={{padding: '0px 5px !important'}}>{quesAns.answer === 'Yes' ? <span className="fa fa-check"/> : <span/>} </td> 
        <td colSpan={2} className="objectiveTd" style={{padding: '0px 5px !important'}} >{quesAns.answer === 'No' ? <span className="fa fa-check"/> : <span/>} </td> </> 
    }

    render() {
        const {questionAndAnswer, submittedBy, submittedDate, plantId, updatedBy, PsName, lastUpdatedDate, isPermanent} = this.props.formData;
        const {inspectionName, frequency, actualDate, facilityType, permitType} = this.reportsMetaData; 
        let qtrYr =  { question: 'Quarter/year', answer: calculateActualTitleDate(frequency, actualDate) }
        let dateCollected = { question: 'Date & time collected', answer: questionAndAnswer[2].answer + ' ' + questionAndAnswer[3].answer }
        let dateExamined = { question: 'Date & time examined', answer: questionAndAnswer[4].answer + ' ' + questionAndAnswer[5].answer }
        return <div style={{padding: '20px'}} id="details-page" className="freese-report reports-page-container">
            <div className="report-title">{`${inspectionName} - ${calculateActualTitleDate(frequency, actualDate)}`}</div>
            <div className="pp-printable-data">
                <div className="metadata-set"><span className="question">Plant ID:</span><span className="answer">{plantId}</span></div>
                <div className="metadata-set d-print-none"><span className="question">Facility Type:</span><span className="answer">{facilityType}</span></div>
                <div className="metadata-set d-print-none"><span className="question">Permit Type:</span><span className="answer">{permitType}</span></div>
                <Col className="metadata-set d-print-none"><span className="question">Permanent:</span><span className="answer">{isPermanent}</span></Col> 
                <div className="metadata-set"><span className="question">Submitted:</span><span className="answer">{submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z' ), 'MM-dd-yyyy') : 'NA'}</span></div>
                <div className="metadata-set"><span className="question">Submitted By:</span><span className="answer">{submittedBy || 'NA'}</span></div>
                <Col className="metadata-set d-print-none"><span className="question">Last Updated:</span><span className="answer">{lastUpdatedDate ? format(new Date(lastUpdatedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col className="metadata-set d-print-none"><span className="question">Updated By:</span><span className="answer">{updatedBy || 'NA'}</span></Col>
            </div>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Plant ID:</span><span className="answer">{plantId}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Facility Type:</span><span className="answer">{facilityType}</span></Col>  
            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Permit Type:</span><span className="answer">{permitType}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Submitted:</span><span className="answer">{submittedDate ? format(new Date(submittedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Submitted By:</span><span className="answer">{submittedBy || 'NA'}</span></Col>
            </Row>
            <Row className="submitted-data screen-submitted-data">
                <Col sm={3} lg={3} className="metadata-set"><span className="question">Permanent:</span><span className="answer">{isPermanent}</span></Col>
                <Col sm={4} lg={4} className="metadata-set"><span className="question">Last Updated:</span><span className="answer">{lastUpdatedDate ? format(new Date(lastUpdatedDate + 'T17:00:00.00Z'), 'MM-dd-yyyy') : 'NA'}</span></Col>
                <Col sm={5} lg={5} className="metadata-set"><span className="question">Updated By:</span><span className="answer">{updatedBy || 'NA'}</span></Col>
            </Row>
            {/* <hr className="line-break d-print-none"/>*/}
            <Row className="report-table-container">
                <table>
                    <tbody>
                        <tr key="1">
                            {this.renderTableData(4, questionAndAnswer[0])}
                            {this.renderTableData(8, questionAndAnswer[1])} 
                        </tr>
                        <tr key="2">
                            {this.renderTableData(4, qtrYr)}
                            {this.renderTableData(4, dateCollected)} 
                            {this.renderTableData(4, dateExamined)} 
                        </tr> 
                        <tr key="3">
                            {this.renderTableData(4, questionAndAnswer[6])}
                            {this.renderTableData(4, questionAndAnswer[7])}
                            {this.renderTableData(4, questionAndAnswer[8])} 
                        </tr>
                        <tr key="4" > 
                            <td colSpan={12} className="additional-details">
                                <table>
                                    <thead>
                                        <th colSpan={2}>Parameter</th>
                                        <th colSpan={3}>Parameter Description</th>
                                        <th colSpan={2} >Yes</th>
                                        <th colSpan={2} style={{padding: '0px 5px'}}>No</th>
                                        <th colSpan={3}>Parameter Characteristics</th>
                                    </thead>
                                    <tbody>
                                        <tr key="5">
                                            <td colSpan={2}><b>Color</b></td>
                                            <td colSpan={3}>Does the water appear to be colored?</td>
                                            {this.renderYesNO(questionAndAnswer[10])} 
                                            <td colSpan={3}> <b>Describe</b>: {questionAndAnswer[11].answer} </td> 
                                        </tr>
                                        <tr key="6">
                                            <td colSpan={2}><b>Clarity</b></td>
                                            <td colSpan={3}>Is the water clear or transparent, meaning can you see through it?</td> 
                                            {this.renderYesNO(questionAndAnswer[13])} 
                                            <td colSpan={3}> <b>Which of the following best describes the clarity of the water?</b> {questionAndAnswer[14].answer} {questionAndAnswer[14].answer==='Other' && `- ${questionAndAnswer[14].subQuestions[0].answer}`} </td> 
                                        </tr>
                                        <tr key="7">
                                            <td colSpan={2}><b>Oil Sheen</b></td>
                                            <td colSpan={3}>Can you see a rainbow effect or sheen on the water surface?</td> 
                                            {this.renderYesNO(questionAndAnswer[16])} 
                                            <td colSpan={3}><b> Which of the following best describes the water sheen?</b> {questionAndAnswer[17].answer} </td> 
                                        </tr>
                                        <tr key="8">
                                            <td colSpan={2}><b>Odor</b></td>
                                            <td colSpan={3}>Does the sample have an odor?</td> 
                                            {this.renderYesNO(questionAndAnswer[19])}  
                                            <td colSpan={3}> <b>Describe</b>: {questionAndAnswer[20].answer}</td> 
                                        </tr>
                                        <tr key="9">
                                            <td colSpan={2}><b>Floating Solids</b></td>
                                            <td colSpan={3}>Is there something floating on the surface of the sample?</td> 
                                            {this.renderYesNO(questionAndAnswer[22])}  
                                            <td colSpan={3}> <b>Describe</b>: {questionAndAnswer[23].answer} </td> 
                                        </tr>
                                        <tr key="10">
                                            <td colSpan={2}><b>Suspended Solids</b></td>
                                            <td colSpan={3}>Is there something suspended in the water column or sample?</td> 
                                            {this.renderYesNO(questionAndAnswer[25])} 
                                            <td colSpan={3}> <b>Describe</b>: {questionAndAnswer[26].answer} </td> 
                                        </tr>
                                        <tr key="11">
                                            <td colSpan={2}><b>Settled Solids</b></td>
                                            <td colSpan={3}>Is there something settled at the bottom of the sample?</td> 
                                            {this.renderYesNO(questionAndAnswer[28])} 
                                            <td colSpan={3}> <b>Describe</b>: {questionAndAnswer[29].answer} </td> 
                                        </tr>
                                        <tr key="12">
                                            <td colSpan={2}><b>Foam</b></td>
                                            <td colSpan={3}>Is there foam or material forming on top of the water?</td> 
                                            {this.renderYesNO(questionAndAnswer[31])}  
                                            <td colSpan={3}> <b>Describe</b>: {questionAndAnswer[32].answer} </td> 
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr> 
                        <tr key="13" className='concern-details'>
                            {this.renderTableData(12, questionAndAnswer[33])}
                        </tr>
                    </tbody>
                </table>
            </Row> 
            <Row className="declarations">
                {this.renderDeclaration()}
            </Row>
        </div>;
    }
}

export function mapStateToProps(state, ownProps) {
    return {};
}

export const FreeseReportDetailer = connect<{}, IFreeseReportDetailerState, IFreeseReportDetailerProps>(mapStateToProps)(FreeseReportDetailerImpl as any);

const Label = ({children, isFlagged, icon}) => {
    return <div
        style={{fontSize: '18px', color: `${isFlagged ? '#A4BF43' : 'gray'}`}}
    >
        {isFlagged ?
            icon
            : ''}
        {children}
    </div>;
};

const Value = ({children}) => {
    return <div style={{fontSize: '18px', marginBottom: '0.5vh'}}>{children}</div>;
};
