import {format, getQuarter} from 'date-fns';
import {CircularProgress} from 'material-ui';
import * as React from 'react';
import {IHistory} from '../../interfaces';
import {PlantModel} from '../../model/PlantModel';
import {Row, Col} from 'react-bootstrap';
import {SettledFormModel} from '../../model/SettledFormModel';
import {getSettledForms} from '../../services/plantService';
import {Async} from '../reusableComponents/Async';
import {ModalError} from '../reusableComponents/ModalError';
import {ModalLoader} from '../reusableComponents/ModalLoader';
import {FooterCard} from './FooterCard';
import {getCurentDate, isMobileDevice, optimizeInspections} from '../../utils/generalUtils';
import './inspections.scss';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import moment from 'moment';

const queryString = require('query-string');

export interface IListFooterProps {
    instances?: PlantModel[];
    index?: any;
    id?: string;
    onClose?: Function;
    history?: IHistory;
    location?: {
        search: string;
    };
    /* modalType?: string; */
}

export interface IListFooterState {
    date: Date;
    settledFormInstance: any;
}

export class ListFooterImpl extends React.Component<IListFooterProps, IListFooterState> {

    static defaultProps: IListFooterProps;
    //identifier = `InspectionFooter${this.props.index}`;
    identifier = `InspectionFooter`;

    constructor(props: IListFooterProps) {
        super(props);
        /* let date = new Date(); */
        let date = getCurentDate(moment(), 'MM/DD/yyyy', true);
        /* let quarter = getQuarter(date).toString().length > 1 ?
            getQuarter(date).toString() : `0${getQuarter(date).toString()}`; */
        let quarter = getQuarter(date).toString().length > 1 ?
            getQuarter(date).toString() : `0${getQuarter(date).toString()}`;
        if (this.props.location?.search) {
            const {search} = this.props.location;
            if(!isMobileDevice()) {
                date = new Date(queryString.parse(search).date);
            }
            //let resDate = queryString.parse(search).date.toString().replace(/-/g, '/');

            //date = getCurentDate(resDate, 'MM/DD/yyyy', true);
            
            /* alert(resDate+' - '+new Date(queryString.parse(search).date)+ ' - '+ moment(queryString.parse(search).date) + ' - ' + queryString.parse(search).date + ' - ' + date)  */
            /* if(date==='Invalid Date') {
                date = getCurentDate(moment(), 'MM/DD/yyyy', true);
            } */
            quarter = queryString.parse(search).quarter ? queryString.parse(search).quarter : quarter;
        }
        this.state = {
            date,
            settledFormInstance: '',
        };
    }

    promise = async () => { 
        if(this.props.id) {
            /* await getSettledForms(format(new Date(this.state.date.toString().replace(/-/g, '/')), 'MMddyyyy'), this.props.id); */
            await getSettledForms(getCurentDate(this.state.date, 'MMDDyyyy'), this.props.id);
            const settledFormInstance = SettledFormModel.get(this.props.id);
            this.setState({settledFormInstance})
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.index !== nextProps.index) {
            this.setState({
                //date: new Date()
                date: getCurentDate(moment(), 'MM/DD/yyyy', true)
            }); 
            const settledFormInstance = SettledFormModel.get(nextProps.id);
            this.setState({settledFormInstance})
        }
    } 
    onDateChange() {
        if(this.props.id) { 
            const settledFormInstance = SettledFormModel.get(this.props.id);
            this.setState({settledFormInstance})
        }
    }
    filterInspectionsByType = (inspections: any, inspectionType: string) => {
        const filteredInspections: any = {};
        
        for (const category in inspections) {
            if (inspections.hasOwnProperty(category)) {
            filteredInspections[category] = {};
        
            for (const frequency in inspections[category]) {
                if (inspections[category].hasOwnProperty(frequency)) {
                const filteredArray = inspections[category][frequency].filter(
                    (inspection: any) => inspection.InspectionType === inspectionType
                );
        
                if (filteredArray.length > 0) {
                    filteredInspections[category][frequency] = filteredArray;
                }
                }
            }
            }
        }
        
        return filteredInspections;
    };
      
    renderCards = () => { 
        if(this.props.id) { 
            let settledFormInstance = this.state.settledFormInstance;
            const plantInfo = PlantModel.get(this.props.id);
            if (!plantInfo || !settledFormInstance) {
                return <div style={{
                    minHeight: '25vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <CircularProgress size={150} color="#A4BF43" thickness={5}/>
                </div>;
            } 
            const inspections = optimizeInspections(settledFormInstance.props.inspections); 

            let InspectionType = "HouseKeeping";
            if ( this.props.history?.location.pathname == '/housekeeping') {
                InspectionType = "HouseKeeping";
            } else if ( this.props.history?.location.pathname == '/inspections') {
                InspectionType = "Inspections";
            }
            const filteredInspections = this.filterInspectionsByType(inspections, InspectionType);
            const hasValues = Array.isArray(filteredInspections) && filteredInspections.some(obj => Object.keys(obj).length > 0);
            /* const hasValues = filteredInspections.some(obj =>
                Object.values(obj).some(nestedObj =>
                  Object.values(nestedObj).some(nestedArr =>
                    (nestedArr as any[]).some(form =>
                      Object.keys(form).length > 0
                    )
                  )
                )
              ); */
            if (Object.keys(filteredInspections).length > 0) {
                return (
                    <div className="inspections-list-parent-container">
                        {Object.keys(filteredInspections).map((permitType: string, index: number) => {
                            return (
                                <React.Fragment key={permitType + index}>
                                    {Object.keys(filteredInspections[permitType]).length > 0 ? (
                                        <Row className="permit-type-container">
                                            <Col sm="12">
                                                <p className="permit-type-header">{permitType}</p>
                                            </Col>
                                            {Object.keys(filteredInspections[permitType]).map((freq, i) => {
                                                return (
                                                    <Col className="inspections-list" key={permitType + freq + i}>
                                                        <div className="freq-title">{freq}</div>
                                                        <FooterCard
                                                            // @ts-ignore
                                                            date={this.state.date}
                                                            permitType={permitType}
                                                            freq={freq}
                                                            plantInfo={plantInfo}
                                                            inspections={filteredInspections[permitType][freq]}
                                                            /* modalType={this.props.modalType} */
                                                            onDateChange={() => {
                                                                this.onDateChange();
                                                            }}
                                                        ></FooterCard>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    ) : 
                                    Object.keys(filteredInspections).length == 1 && index == 0 ? <div className='No_Inspection'><h4>No Inspection Found...</h4></div>
                                    :
                                    // null
                                    Object.keys(filteredInspections).length == Object.keys(filteredInspections).length && index == 0 ? <div className='No_Inspection'><h4>No Inspection Found...</h4></div>: null
                                    // <div className='No_Inspection'><h4>...{Object.keys(filteredInspections).length} --- {index} ...</h4></div>
                                    }
                                </React.Fragment>
                            );
                        })}
                    </div>
                );
            } else {
                return <div className='No_Inspection'><h4>No Inspection Found...</h4></div>;
            }

        } else {
            return <div className='No_Inspection'><h4>No Inspection Found...</h4></div>;
        }
    }

    renderContent = () => { 
        return (
            <>
                <Async
                    identifier={this.identifier}
                    promise={this.promise}
                    loader={<ModalLoader/>}
                    content={this.renderCards()}
                    error={<ModalError/>}
                />
            </>
        )
    }

    render() {
        return this.renderContent();
    }
}

export function mapStateToProps(state) {
    return { };
}

export const ListFooter = withRouter(connect< IListFooterProps, any, any>(mapStateToProps)(ListFooterImpl as any)) 

