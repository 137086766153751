import {getQuarter, format} from 'date-fns';
import * as React from 'react';
import {Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'; 
import {setLoading, setSuccess} from '../../../actions/loadingActions';
import {quarters, years} from '../../../constants/generals';
import {IFilters, IHistory, IQuarterlyForms} from '../../../interfaces';
import {QuarterlyFormModel} from '../../../model/QuarterlyFormModel';
import {getYearlyFormList} from '../../../services/yearlyFormService';
import {BasePage} from '../../reusableComponents/BasePage';
import {BarChart} from '../../reusableComponents/ChartComponents/BarChart';
import {PieChart} from '../../reusableComponents/ChartComponents/PieChart';
import {ListPage} from '../../reusableComponents/ListPage';
import {RegionFilter} from '../../reusableComponents/RegionFilter';
import {SelectDropdown} from '../../reusableComponents/SelectDropdown';
import {ListItem} from '../InspectionReportDetailer/ListItem';
import { getRegionsByOrg } from '../../../services/orgService';
import { getUserData } from '../../../services/loginService';
import { RegionModel } from '../../../model/OrgModel';
import moment from 'moment';
import { getCurentDate } from '../../../utils/generalUtils';
import { Pagination } from '../../reusableComponents/Pagination';

const queryString = require('query-string');

export interface IYearlyReportsProps {
    instances: any;
    quarterlyForms?: IQuarterlyForms;
    filters?: IFilters;
    history?: IHistory;
    location?: any;
    axiosPreviousToken: any;
    regionList: [];
}

export interface IYearlyReportsState {
    data: Array<any>;
    filterBy: string; 
    region: string;
    year: any; 
    isAsc: boolean;
    sortIndex: any;
    curPageNo: any;
    searchText: any;
    totCount: any;
}

export class YearlyReportsImpl extends React.Component<IYearlyReportsProps, IYearlyReportsState> {
    loggedInUserInfo = getUserData();
    constructor(props: IYearlyReportsProps) {
        super(props);
        //let year = new Date().getFullYear().toString();
        let year = getCurentDate(moment(), 'yyyy')
        let region = 'All'; 
        if (this.props.location.search) { 
            region = queryString.parse(this.props.location.search).region;
            year = queryString.parse(this.props.location.search).year;
        }
        this.state = {
            data: [],
            region,
            filterBy: '',  
            year: year,
            isAsc: false,
            sortIndex: 'submittedDate',
            curPageNo: 1,
            searchText:'',
            totCount:0  
        };
    }

    promise = async () => {
        //let year = new Date().getFullYear().toString();
        let year = getCurentDate(moment(), 'yyyy')
        let region = 'All'; 
        if (this.props.location.search) { 
            region = queryString.parse(this.props.location.search).region;
            year = queryString.parse(this.props.location.search).year;
        } 
        await getYearlyFormList(region, year, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText).then(res => {
            this.setState({
                data: res?.data && res.data.data && res.data.data.length ? res.data.data : [],
                region: region,
                totCount: res?.data.TotalCount
            });
        });
        if(RegionModel.list().length===0) {
            await getRegionsByOrg(this.loggedInUserInfo.orgId)
        } 
    }

    renderLineChart = () => {
        const {quarterlyForms} = this.props;
        if (!quarterlyForms) {
            return <div/>;
        }

        const quarterlyFormsData: Array<any> = [];
        quarterlyFormsData.push({
            name: 'Quarter Visual', 'Complete Forms': quarterlyForms.QTRVisual.Complete,
            'Incomplete Forms': quarterlyForms.QTRVisual.InComplete
        });
        quarterlyFormsData.push({
            name: 'ShopInspection', 'Complete Forms': quarterlyForms.ShopInspection.Complete,
            'Incomplete Forms': quarterlyForms.ShopInspection.InComplete
        });
        return <Row className="show-grid line-chart-container" style={{display: 'flex', justifyContent: 'center'}}>
            <Col sm={6} xs={12}>
                <BarChart
                    barColors={['#63AB66', '#DBDBDB']}
                    dataKey={['Complete Forms', 'Incomplete Forms']}
                    data={quarterlyFormsData}
                />
            </Col>
        </Row>;
    }

    renderPieChart = () => {
        const {quarterlyForms} = this.props;
        if (!quarterlyForms) {
            return <div/>;
        }
        const {filterBy} = this.state;
        const dailyFormSummary =
            [{name: 'Complete Forms', value: quarterlyForms[filterBy].Complete},
                {name: 'Incomplete Forms', value: quarterlyForms[filterBy].InComplete}];

        return <Row className="show-grid">
            <Col sm={12} xs={12} className="pie-chart-container">
                <PieChart className="pie-chart-responsive" data={dailyFormSummary} dataKey="value" legends>
                </PieChart>
            </Col>
        </Row>;
    }
    handleDateFilter = async (e: any) => {
        this.setState({ year: e }, await this.renderLoadData);
        /* setLoading('DailyReports');
        const queryString = `?year=${e}&&region=${this.state.region}`;
        await getYearlyFormList( this.state.region, e).then(res => {
            const content = res?.data && res.data.data && res.data.data.length ? res.data.data : [];
            this.setState({
                data: content
            });
        });
        this.props.history?.push(`/reports/daily${queryString}`);
        setSuccess('DailyReports'); */
        //await this.renderLoadData();
    }

    handleRegionFilter = async (a, value) => { 
        this.setState({region: value}, await this.renderLoadData);
       /*  const queryString = `?region=${value}`;
        await getYearlyFormList(value, this.state.year).then(res => {
            this.setState({
                data: res?.data && res.data.data && res.data.data.length ? res.data.data : []
            });
        });
        this.props.history?.push(`/reports/yearly${queryString}`);
        setSuccess('YearlyFormList'); */
        //await this.renderLoadData();
    } 
    async renderLoadData() {
        setLoading('YearlyFormList');
        const queryString = `?year=${this.state.year}&&region=${this.state.region}`;
        await getYearlyFormList( this.state.region, this.state.year, this.state.isAsc, this.state.sortIndex, this.state.curPageNo, this.state.searchText, this.props.axiosPreviousToken).then(res => {
            const content = res?.data && res.data.data && res.data.data.length ? res.data.data : [];
            this.setState({
                data: content,
                totCount: res?.data.TotalCount
            });
        });
        this.props.history?.push(`/reports/yearly${queryString}`);
        setSuccess('YearlyFormList');
    }

    handleSort = async (Asc, index, searchText) => {
        await this.setState({
            isAsc: Asc,
            curPageNo: searchText.length > 0 && this.state.searchText == searchText ? this.state.curPageNo : 1,
            sortIndex: index,
            searchText: searchText,
        })
        await this.renderLoadData();
    }

    render() {
        const {filterBy,totCount} = this.state;
        let pageno = Math.ceil(totCount/5 );

        
        return (
            <BasePage className="list-page-base yearly-reports" pageTitle="Yearly Reports">
                <ListPage
                    // @ts-ignore
                    identifier="YearlyFormList"
                    instances={this.state.data}
                    frequency={'Yearly'}
                    listItemComponent={ListItem}
                    searchBy={['plantId', 'plantName', 'plantRegionName', 'permitType', 'inspectionName']}
                    instanceOf={'plantInfo'}
                    pageHeadings={['#', 'PlantId', 'Region', 'Facility', 'Permit', 'Form', 'Submitted', 'Last Updated']}
                    listItemFields={['', 'plantId', 'plantRegionName', 'facilityType', 'permitType', 'inspectionName',  'submittedDate', 'lastUpdatedDate']}
                    cols={[1, 1, 2, 1, 1, 2, 2, 2]}
                    promise={this.promise}
                    onHandleSort={this.handleSort}
                    searchPlaceHolder="Enter plant id, plant name, region, permit type, inspection to search..."
                    emptyListMessage="No Yearly Reports found"
                    chartComponents={filterBy && filterBy !== 'all' ? this.renderPieChart() : this.renderLineChart()}
                    chartTitle=""
                    filterBy={filterBy}
                    chartFilter={<div className="filter-container row"> 
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                            <SelectDropdown
                                onChange={this.handleDateFilter}
                                menuItems={years()}
                                defaultValue={this.state.year}
                            />
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                            <RegionFilter
                                selectedValue={this.state.region}
                                onChange={this.handleRegionFilter}
                                regionList={this.props.regionList} 
                            /> 
                        </Col> 
                    </div>}
                />
                <Pagination
                    promise={async (e: any) => {
                        await this.setState({ curPageNo: e});
                        // this.handlePagination(); // Call the function inside the async arrow function
                        await this.renderLoadData();

                    }}
                    totalCount={pageno}
                    // Model={OIModel as any} 
                    pageNum={this.state.curPageNo}
                    identifier="AllListPage"
                />
            </BasePage>
        );
    }
}

export function mapStateToProps(state) {
    const instances = QuarterlyFormModel.list(state);
    const axiosPreviousToken = state.miscellaneous.get('axiosPreviousToken');
    const regionList = RegionModel.list();
    return {
        instances, regionList, axiosPreviousToken
    };
} 
export const YearlyReports = withRouter(connect< IYearlyReportsProps, any, any>(mapStateToProps)(YearlyReportsImpl));

