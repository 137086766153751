import S3Client from 'aws-s3';
import axios from 'axios';
import {format} from 'date-fns';
import * as React from 'react';
import 'whatwg-fetch';
import * as _ from 'lodash';
import moment from "moment";
import {IAction, IThunkAction} from '../interfaces';
import {store} from '../store';
import {monthsAndYears, SubmittedBy, tickBase64} from '../constants/generals';
import {getUserData, parseJwt} from '../services/loginService';
import {pushAlert, removeAlert, setTimeOutID} from '../actions/loginActions';
import {PlantModel} from '../model/PlantModel';
import {UserModel} from '../model/UserModel'; 
import {setSuccess} from '../actions/loadingActions';
import {isImageLoading} from '../actions/miscellaneousActions';
import { Loader } from '../components/reusableComponents/Loader';
import { stringify } from 'querystring';
import { IHistory } from '../interfaces';
declare const require: any;
const AWS = require('aws-sdk'); 
declare const Buffer;

export function keys(object: Object, callback: Function) {
    for (let key in object) {
        if (object.hasOwnProperty(key)) {
            callback(key);
        }
    }
}

export function stringToColour(str, hashColors: any = []) { 
    let hash = 0; 
    for (let i = 0; i < str.length; i++) {  
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    } 
    let colour = '#';
    for (let i = 0; i < 3; i++) { 
        let value = (hash >> (i * 8)) & 0xFFF; 
        colour += ('00' + value.toString(16)).substr(-2); 
    }  
    let blockedHash = ['#180112'];  
    return blockedHash.indexOf(colour)>-1?'#a588a5':colour; 
    
}

export function selectedOutfallsCountCalculator(outFalls) {
    let selectedOutfalls = _.filter(outFalls, {IsSelected: true});
    return selectedOutfalls.length;
}

export function optimizeInspections(inspections) {
    let finalList = {};
    if (!_.isEmpty(inspections)) {
        ['Water', 'Air', 'Waste', 'Others'].map((permitType) => {
            const dailyInspections = _.filter(inspections[permitType]['Daily'], {IsSelected: true});
            const weeklyInspections = _.filter(inspections[permitType]['Weekly'], {IsSelected: true});
            const monthlyInspections = _.filter(inspections[permitType]['Monthly'], {IsSelected: true});
            const quarterlyInspections = _.filter(inspections[permitType]['Quarterly'], {IsSelected: true});
            const yearlyInspections = _.filter(inspections[permitType]['Yearly'], {IsSelected: true});
            if (dailyInspections.length) {
                if (!finalList[permitType]) {
                    finalList[permitType] = {};
                }
                _.forEach(dailyInspections, (inspection) => {
                    inspection['PointSources'] = _.filter(inspection['PointSources'], {IsSelected: true});
                });
                finalList[permitType]['Daily'] = dailyInspections;

            }
            if (weeklyInspections.length) {
                if (!finalList[permitType]) {
                    finalList[permitType] = {};
                }
                _.forEach(weeklyInspections, (inspection) => {
                    inspection['PointSources'] = _.filter(inspection['PointSources'], {IsSelected: true});
                });
                finalList[permitType]['Weekly'] = weeklyInspections;
            }
            if (monthlyInspections.length) {
                if (!finalList[permitType]) {
                    finalList[permitType] = {};
                }
                _.forEach(monthlyInspections, (inspection) => {
                    inspection['PointSources'] = _.filter(inspection['PointSources'], {IsSelected: true});
                });
                finalList[permitType]['Monthly'] = monthlyInspections;
            }

            if (quarterlyInspections.length) {
                if (!finalList[permitType]) {
                    finalList[permitType] = {};
                }
                _.forEach(quarterlyInspections, (inspection) => {
                    inspection['PointSources'] = _.filter(inspection['PointSources'], {IsSelected: true});
                });
                finalList[permitType]['Quarterly'] = quarterlyInspections;
            }
            if (yearlyInspections.length) {
                if (!finalList[permitType]) {
                    finalList[permitType] = {};
                }
                _.forEach(yearlyInspections, (inspection) => {
                    inspection['PointSources'] = _.filter(inspection['PointSources'], {IsSelected: true});
                });
                finalList[permitType]['Yearly'] = yearlyInspections;
            }
            return true;
        });
    }

    return finalList;
}

export function isEmpty(obj: Object) {
    return Object.keys(obj).length <= 0 || !obj;
}

export function dispatch<T extends IAction>(action: T | IThunkAction) {
    if ((action as IAction).type) {
        return store.dispatch(action as IAction);
    }
    return store.dispatch<any>(action as IThunkAction);
}

export function getQuarter(d) {
    d = d || new Date();
    const q = [4, 1, 2, 3];
    return q[Math.floor(d.getMonth() / 3)];
}

export function splitDate(date: string) {
    const yyyy = date.split('-')[0];
    const mm = date.split('-')[1];
    const dd = date.split('-')[2] || '01';
    return {
        dd, mm, yyyy
    };
}

export function splitDateIE(date: string) {
    const mm = date.split('-')[0];
    const dd = date.split('-')[1];
    const yyyy = date.split('-')[2] || '01';
    return {
        dd, mm, yyyy
    };
}
export function setFilters(filters: any, history: IHistory) {
    const {location: {pathname},} = history;
    history.push(pathname + `?${stringify(filters)}`)
    //history.push({ pathname, search: stringify(filters) });
}
export function formatArrayItemDisplay(value: Array<any>, property: string) {
    let finalData: any = '';
    finalData = _.map(value, property);
    if (finalData.length > 0) {
        finalData = _.join(finalData, ', ');
    }
    return finalData;
}

export function frameSelectOptions(list, valueProp: string, labelProp: string) {
    const optionsList: Array<any> = [];
    // optionsList.push({label: 'All', value: 'All'});
    list.forEach((obj: any) => {
        optionsList.push({label: obj[labelProp], value: obj[valueProp], ...obj});
    });
    return optionsList;
}

export function isFormEmpty(values: any, notRequired?: Array<any>) {
    if (!notRequired) {
        notRequired = [];
    }
    for (const key in values) {
        if (notRequired.indexOf(key) > -1) {
            continue;
        }
        if (!values[key] || values[key] && values[key].length <= 0) {
            return true;
        }
    }
}

export function convertTime(time: any) { 
    if (time.split(':')[0] < 12) {
        return '0' + time.split(':')[0] % 12 + ':' +
            time.split(':')[1] + ' AM';
    } else if (time.split(':')[0] === '00') {
        return '00' + ':' + time.split(':')[1] + ' AM';
    } else if (time.split(':') === 12) {
        return '12' + time.split(':')[1] + 'PM';
    }
    return time.split(':')[0] % 12 > 10 ? time.split(':')[0] % 12 + ':' +
        time.split(':')[1] + ' PM' : '0' + time.split(':')[0] % 12 + ':' +
        time.split(':')[1] + ' PM';
}
/*declare module 'react' {
    type Provider<T> = React.ComponentType<{
        value: T;
        children?: ReactNode;
    }>;
    type Consumer<T> = ComponentType<{
        children: (value: T) => ReactNode;
        unstable_observedBits?: number;
    }>;

    interface Context<T> {
        Provider: Provider<T>;
        Consumer: Consumer<T>;
    }

    function createContext<T>(defaultValue: T, calculateChangedBits?: (prev: T, next: T) => number): Context<T>;
}*/

export const ModelContext = React.createContext('');

export function resetModels() {
    PlantModel.deleteAll();
    UserModel.deleteAll();
}

export function getCurrentUserUUID() {
    return store.getState().login.get('UniqueId');
}

/*export function marshallAnswers(values, formType?: string) {
    const answer: {
        quesNum: number;
        answer: string;
    }[] = [];
    for (const key in values) {
        if (key === '0') {
            continue;
        }
        if (formType && ((formType === 'MonthlySW' && Number(key) > 60) || (formType === 'MonthlySpillLogs' && Number(key) > 4))) {
            continue;
        }
        answer.push({
            quesNum: Number(key),
            answer:
                (values[key] !== null && typeof (values[key]) === 'boolean') ?
                    (values[key] === true ? 'Yes' : 'No') :
                    values[key]
        });
    }
    answer.sort((a, b) => {
        return a.quesNum - b.quesNum;
    });
    return answer;
}*/

/*export function marshallAnswersPlantPreventive(values) {
    const answer: {
        quesNum: number;
        answer: string;
    }[] = [];
    for (const key in values) {
        if (key === '0') {
            continue;
        }
        answer.push({
            quesNum: Number(key),
            answer:
                (values[key] !== null) ?
                    ((values[key]) === 'N*' ? 'Ok' : (values[key]) !== 'Y*' && key !== '54'
                        ? `Needs work-${values[key]}` : values[key])
                    : values[key]
        });
    }
    answer.sort((a, b) => {
        return a.quesNum - b.quesNum;
    });
    return answer;
}*/

export function marshallChecklistData(data) {
    const answersData: Array<any> = [];
    let date = '';
    let flagged = false;
    let flag = true;
    for (const key in data) {
        flag = false;
        date = key;
        const sURL = data[key].Signature_URL;
        const aDate = data[key].Date;
        if (data[key] instanceof Object) {
            for (const key1 in data[key]) {
                if (key1.toLowerCase() === 'flagged') {
                    flagged = data[key][key1];
                }
                if (data[key][key1] instanceof Object) {
                    const answers: Array<any> = [];
                    flag = true;
                    for (const key2 in data[key][key1]) {
                        if (key2 !== '61') {
                            answers.push({
                                answer: {
                                    quesNum: key2,
                                    answer: data[key][key1][key2],
                                    date,
                                    flagged,
                                }
                            });
                        };
                    }
                    answers.push({
                        answer: {
                            quesNum: '61',
                            answer: aDate,
                            date,
                            flagged,
                        }
                    });
                    answers.push({
                        answer: {
                            quesNum: '62',
                            answer: sURL,
                            date,
                            flagged,
                        }
                    });
                    answersData.push({month: key, data: answers, entryDate: data[key]['Date']});
                }
            }
        }
    }
    return answersData;
}

export async function uploadImageToS3(file, filename, dirName) {
    AWS.config.update({
        accessKeyId: 'AKIA47N26QZ4FZMVBMMY',
        secretAccessKey: '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',
    });
    AWS.config.region = 'us-west-2';
    const s3 = new AWS.S3();

    const myBucketName = 'enviroconnect';
    const params = {
        Bucket: myBucketName,
        Key: `${dirName}/${filename}`,
        Body: file,
        ContentType: file.type,
        ACL: 'public-read'
    };
    return s3.putObject(params, function (err, data) {
        if (err) {
            throw err;
        } else {
            return true;
        }
    });
}

// export async function uploadImageToS3(file, filename, dirName) {

//     const config = {
//         bucketName: 'enviroconnect', // 'harish-soni-1',
//         dirName: dirName,  //  'testing', /* optional */
//         region: 'us-west-2', // 'us-east-1',
//         accessKeyId: 'AKIAI3PI3RO6NAQ4MIFQ',
//         secretAccessKey: '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',
//     };
//     file.filename = filename;
//     return S3Client
//         .uploadFile(file, config)
//         .then(data => { return data; })
//         .catch(err => console.error(err, 'asdasdsa'));

// }

export async function deleteImageFromS3(file, dirName) {

    const config = {
        bucketName: 'enviroconnect', // 'harish-soni-1',
        dirName: dirName,  //  'testing', /* optional */
        region: 'us-west-2', // 'us-east-1',
        accessKeyId: 'AKIAI3PI3RO6NAQ4MIFQ',
        secretAccessKey: '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',
    };

    return S3Client
        .deleteFile(file, config)
        .then(data => {
            return data;
        })
        .catch(err => console.error(err));

}

export function base64ToFile(datURL, filename) {
    const arr = datURL.split(',');
    const mime = arr[0].match(/:(.*?);/) && arr[0].match(/:(.*?);/)[1];
    let bstr: any = null;
    let u8arr: any = null;
    if (arr[1]) {
        bstr = atob(arr[1]);
        let n = bstr.length;
        u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
    }
    return {file: new Blob([u8arr], {type: 'image/png'}), filename};
}

// export async function base64ToFile(dataURL, fileName) {
//     const arr = dataURL.split(',');
//     const mime = arr[0].match(/:(.*?);/)[1];
//     return (fetch(dataURL)
//         .then(function (result) {
//             return result.arrayBuffer();
//         })).then(function (buffer) {
//             return new File([buffer], fileName, { type: mime });
//         }).catch(function () {
//             console.error('Error in Fetch');
//         });
// }

export async function asyncForEach(array: any[], callback: Function) {
    for (let i = 0; i < array.length; i++) {
        await callback(array[i], i, array);
    }
}

export function compressBase64Image(base64String: string, callback) {
    isImageLoading(true);
    const canvas = document.createElement('canvas');
    let image64 = '';
    const canvasContext = canvas.getContext('2d');
    canvas.height = 75;
    canvas.width = 90;
    const image = new Image();
    image.src = base64String;
    image.onload = (e) => {
        canvasContext?.drawImage(image, 0, 0, image.width, image.height,
            0, 0, canvas.width, canvas.height);
        image64 = canvas.toDataURL();
        callback(image64);
    };
}

export function getBase64(url) {
    return axios.get(url, {
        responseType: 'arraybuffer',
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        },
    }).then(response => new Buffer(response?.data, 'binary').toString('base64'));
}


export function toDataUrl(url: any, callback: (imgData: any) => void) {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
        let reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.send();
}  
/* export function getBase64TEST(url: string) {
    return axios.get(url, {
        responseType: 'arraybuffer',
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        },
    }).then(response => new Buffer(response.data, 'binary').toString('base64'));
} */


export function formatDate(date, dateFormat = 'yyyy-MM-dd', addZone: boolean = false) { 
    return addZone ? ( dateFormat ? format(new Date(date + 'T17:00:00.00Z'), dateFormat) : new Date(date + 'T17:00:00.00Z')) : format(new Date(date), dateFormat);
}
/* export function getCurentDate(date, dateFormat = 'MM-DD-yyyy', needDateObj = false ) {
    let dateRes: any = moment(date).format(dateFormat);
    return needDateObj?moment(moment(date).format(dateFormat)).toDate() :dateRes;
} */
/* export function getCurentDate(date, dateFormat = 'MM-DD-YYYY', needDateObj = false) {
    // If the date is a number, assume it's a year and create a date object for January 1st of that year
    if (typeof date === 'number') {
        date = moment([date]);
    } else {
        date = moment(date);
    }
    
    // Format the date according to the provided format
    let dateRes = date.format(dateFormat);

    // Return either the formatted date string or a JavaScript Date object
    return needDateObj ? moment(dateRes, dateFormat).toDate() : dateRes;
} */
/* export function getCurentDate(date, dateFormat = 'MM-DD-YYYY', needDateObj = false) {
    // If the input is already a Moment.js object, no need for parsing --- last
    let dateObj = moment(date);
    let rees = 'getCurentDate rees = '+dateObj.isValid()+' typeof date '+typeof(date)+' date '+date
    alert(rees)
    // If the provided date is not valid, return 'Invalid Date'
    if (!dateObj.isValid()) {
        return 'Invalid Date';
    }

    // Format the date according to the provided format
    let dateRes = dateObj.format(dateFormat);
    let res = 'date.toDate() '+dateObj.toDate()+' dateRes '+dateRes
    alert(res)
    // Return either the formatted date string or a JavaScript Date object
    return needDateObj ? dateObj.toDate() : dateRes;
} */
export function getCurentDate(date, dateFormat = 'MM-DD-YYYY', needDateObj = false) {
    let dateObj;
    if (moment.isMoment(date)) {
        dateObj = date.clone(); // Clone to avoid mutating the original moment object
    }
    // Check if the input is provided and not null or undefined
    else if (date) {
        // If the input is already a Moment.js object, no need for parsing
        dateObj = moment(date);
    } else {
        // If no date is provided, default to the current date
        dateObj = moment();
    }
    // If the provided date is not valid, return 'Invalid Date'
    if (!dateObj.isValid()) {
        return 'Invalid Date';
    }

    // Format the date according to the provided format
    let dateRes = dateObj.format(dateFormat);

    // Return either the formatted date string or a JavaScript Date object
    return needDateObj ? dateObj.toDate() : dateRes;
}
/* export function getCurentDate(date, dateFormat = 'MM-DD-YYYY', needDateObj = false) {
    // If the date is a number, assume it's a year and create a date object for January 1st of that year
    if (typeof date === 'number') {
        date = moment([date]);
    } else {
        date = moment(date, dateFormat, true); // Strict parsing with the specified format
    }
    let rees = 'getCurentDate rees = '+date.isValid()+' typeof date '+typeof(date)+' date '+date
    alert(rees)
    if (!date.isValid()) {
        return 'Invalid Date';
    }

    // Format the date according to the provided format
    let dateRes = date.format(dateFormat);
    let res = 'date.toDate() '+date.toDate()+' dateRes '+dateRes
    alert(res)
    // Return either the formatted date string or a JavaScript Date object
    return needDateObj ? date.toDate() : dateRes;
} */
export function objectToArray(obj) {
    return Object.keys(obj).map(key => {
        return obj[key];
    });
    /* 3 years expect
    2019 april  */
}

export function formatDateToUS(date: string) {
    if (date) {
        const splitDate = date.split('-');
        return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
    }
    return '';
}

export function getBase64Image(img) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
}

export function calculateActualTitleDate(frequency, date) {
    let dateToDisplay: any = null;
    frequency = (frequency || '').toLowerCase()
    switch(frequency) {
        case 'daily':
            dateToDisplay = format(new Date((date.replace(/-/g, '/'))), 'MM/dd/yyyy');
            break;
        case 'weekly': 
            let dateFormat = format(new Date((date.replace(/-/g, '/'))), 'MM/dd/yyyy'); 
            var weeknumber = moment(dateFormat).weeks();
            let calculatedQtrforWeekly = findCurrentQuarter(date); 
            dateToDisplay = `Week - ${weeknumber}, ${calculatedQtrforWeekly.year}`;
            break;
        case 'monthly':
            dateToDisplay = monthsAndYears[new Date(date.replace(/-/g, '/')).getMonth()].label + ' ' + new Date(date.replace(/-/g, '/')).getFullYear();
            break;
        case 'quarterly':
            let calculatedQtr = findCurrentQuarter(date);
            dateToDisplay = calculatedQtr.qtr + ' quarter of ' + calculatedQtr.year;
            break;
        case 'yearly':
            let calculatedQtrforYear = findCurrentQuarter(date);
            dateToDisplay = calculatedQtrforYear.year;
            break;
    } 
    return dateToDisplay;
}
export function calculateCurrentWeekOfMonth(date) {
    let dateFormat = format(new Date((date.replace(/-/g, '/'))), 'MM/dd/yyyy'); 
    let currWeekOfMonth = moment(dateFormat).week() - (moment(dateFormat).month()*4);
    
    return { currWeekOfMonth, currWeekOfMonthWithSuffix: suffixOf(currWeekOfMonth),  year: moment(dateFormat).year(), monthInChar: moment(dateFormat).format('MMMM') }
}
export function processDayofWeek(date) {
    let mDate = moment(date)/* .startOf('week').isoWeekday(1) */;
    let weekday = mDate.weekday();
    var weekDayName =  mDate.format('dddd').substring(0,3);
    let ongoingWeek = mDate.isSame(new Date(), 'week'); //true if dates are in the same week  
    return {weekDayName, weekdayIndex: (weekday-1), ongoingWeek};
}
export function weekdayIndexByDay(day) {
    let weekdayIndex;
    switch(day) {
        case 'Mon': weekdayIndex = 0; break;
        case 'Tue': weekdayIndex = 1; break;
        case 'Wed': weekdayIndex = 2; break;
        case 'Thu': weekdayIndex = 3; break;
        case 'Fri': weekdayIndex = 4; break;
        case 'Sat': weekdayIndex = 5; break;
        case 'Sun': weekdayIndex = 6; break;
        case 'Weekly': weekdayIndex = 7; break;
    }
    return weekdayIndex;
}
export function processMonthofYear(date) { 
    let mDate = moment(date);
    let month = mDate.month();
    let year = mDate.year(); 
    let currentYear = moment().year();
    let ongoingHalfYear = false;
    if( (year===currentYear) ){
        ongoingHalfYear = true;
    }
    return {monthofYear: month<7?6:12, monthIndex: month<7?0:1, ongoingHalfYear, year };
}
export function suffixOf(i: number) {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}
export function marshallFormDetails(instance, index: number, datePlant?) {
    if (instance.question !== SubmittedBy) {
        return {
            answer: instance.Answer,
            question: instance.Question,
            quesNum: index + 1,
            flaggedQuestion: instance.FlaggedQuestion > 0,
            completed: (instance.Completed || -1) > 0,
            completedBy: instance.CompletedBy || '',
            followUpComments: instance.Comment || '',
            dateCompleted: instance.CompletedDate && format(new Date(instance.CompletedDate), 'MM/dd/yyyy')
        };
    }
    return instance;
}

export function getCurrentUserAccessLevel() {
    const userToken = getUserData().token;
    return parseJwt(userToken).Access_Level;
}

/*export function checkAnswerForYesOrNo(answer) {
    return typeof answer === 'boolean' || typeof answer === 'string' ? answer : null;
}*/

export function getCurrentUserCompanyName() {
    const userToken = getUserData().token;
    return parseJwt(userToken).orgName;
}
export function getUserLoginInfo() {
    return parseJwt(getUserData().token)
}

    export function findCurrentQuarter(date) {
    // let cdate = new Date(date.toString().replace(/-/g, '/'));
    let dateformat = ''
    const dateFormats = ['MM-DD-YYYY', 'YYYY-MM-DD'];
    for (let format of dateFormats) {
        if (moment(date, format, true).isValid()) {
            dateformat=  format;
        }
    }
    const cdate = moment(date, dateformat).format('MM/DD/YYYY');
    // let month = cdate.getMonth() + 1;
    // let year = cdate.getFullYear();
    // let month = moment(cdate, 'MM/DD/YYYY').format('MM').toString();
    // let year = moment(cdate, 'MM/DD/YYYY').format('YYYY').toString();
    const month = parseInt(moment(cdate, 'MM/DD/YYYY').format('MM'), 10);
    const year = parseInt(moment(cdate, 'MM/DD/YYYY').format('YYYY'), 10);
  
    let qtr = '';
    if (month <= 3) {
        qtr = '1st';
    } else if (month > 3 && month <= 6) {
        qtr = '2nd';
    } else if (month > 6 && month <= 9) {
        qtr = '3rd';
    } else {
        qtr = '4th';
    }

    return {qtr, year};
}

export function detailsReportPagePdfMake(data) {
    let storeCustomlabel = '';
    let values: Array<any> = [{}];
    let notes: Array<any> = [];
    values = [{
        table: {
            widths: ['50%', '30%', '20%'],
            headerRows: 1,
            body: [
                [{text: 'Inspection Area', fontSize: 8, alignment: 'center', bold: true},
                    {text: 'Needs Work', fontSize: 8, alignment: 'center', bold: true},
                    {text: 'OK', fontSize: 8, alignment: 'center', bold: true},
                ],
            ],
        }
    }];
    data.map((value, index) => {
        if (value.Questiontype === 'YesNoTypeComment' || value.Questiontype === 'subQuestions') {
            values.push({
                table: {
                    widths: ['50%', '30%', '20%'],
                    headerRows: 1,
                    body: [
                        [{text: `${value.customLabel}) ${value.question}`, fontSize: 8},
                            value.answer !== 'Ok' && value.answer !== 'UnAnswered' ?
                            /*tslint:disable-next-line*/
                            {
                                image: tickBase64, width: 7, height: 7, alignment: 'center'
                            } : {text: ''}
                            , value.answer === 'Ok' ?
                            /*tslint:disable-next-line*/
                            {
                                image: tickBase64 , width: 7, height: 7, alignment: 'center'
                            } : {text: ''}
                        ],
                    ],
                }
            });
        } else if (value.Questiontype === 'title') {
            values.push({
                table: {
                    widths: ['100%'],
                    headerRows: 1,
                    body: [
                        [
                            {
                                text: `${value.customLabel || ''} ${value.question}`,
                                fontSize: 8, bold: true, alignment: 'center'
                            },
                        ]
                    ],
                },
            });
        } else if (value.Questiontype === 'headingForSubsections') {
            values.push({
                table: {
                    widths: ['100%'],
                    headerRows: 1,
                    body: [
                        [
                            {text: `${value.customLabel || ''}) ${value.question}`, fontSize: 8},
                        ]
                    ],
                },
            });
        }
        if (value.Questiontype === 'headingForSubsections') {
            storeCustomlabel = value.customLabel;

        } else if (value.Questiontype === 'title') {
            storeCustomlabel = '';
        }
        if (value.answer.indexOf('Needs work-') > -1) {
            if (value.Questiontype === 'YesNoTypeComment') {
                notes.push(
                    `${value.customLabel}: ${value.answer} \t`
                );
            } else if (value.Questiontype === 'subQuestions') {
                notes.push(
                    `${storeCustomlabel}.${value.customLabel}: ${value.answer} \t`
                );
            }
        }
        return false
    });
    return {values, notes};
}

export function getFormattedDate(date = new Date()) {
    date.setDate(date.getDate() + 1);
    return {
        formatted: format(new Date(date), 'yyyy-MM-dd'),
        date
    };
}
export function printDetails(ref: any, fileName: string, isLong: boolean = false) {
    setSuccess('PrintPreviewLoader');
}
// tslint:disable-next-line:max-line-length
export function showAlert(text: string, id: string, type: string, cb?: Function, autoClose: boolean = true, timeOut?: number) {
    pushAlert(text, id, type);
    timeOut = timeOut ? timeOut : 3000;
    if (autoClose) {
        let timeOutID = setTimeout(() => {
            removeAlert(id);
            if (cb instanceof Function) {
                cb();
            }
        }, timeOut);
        setTimeOutID(timeOutID.toString());
    }
}

export function clearSetTimeout(id: string, cb: Function) {
    let timeOutID = store.getState().alert.get('timeOutID');
    clearTimeout(timeOutID);
    cb();
    setTimeout(function () {
        removeAlert(id);
    }, 100);
}

export function getAnswerWithFlag(quest) {
    let followUpLogo = '';
    let followUpClass = '';
    let defaultValue = {};
    if(quest && quest.isFlagged ) {
        if(quest.followUpRemark) {
            followUpLogo = 'fa fa-check-square-o';
            followUpClass = 'followUpRemarkComplete';
            defaultValue = {'followUpRemark': quest.followUpRemark, 'followUpDate': quest.followUpDate }
        } else {
            followUpLogo = 'fa fa-flag';
            followUpClass = 'followUpRemarkUnComplete';
        }
    }  
    return { followUpLogo, followUpClass, defaultValue};
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function getFirstLetters(input: string): string {
    if(!input) 
        return '';
    const words = input.split(' ');
    const firstLetters = words.map(word => word.charAt(0));
    return firstLetters.join('');
}

 
export function isMobileDevice() { 
    return navigator.userAgent.toString().indexOf('Mobile') > -1;
}

export function refreshWindow() {
    <Loader type="submission"/>
    if('caches' in window){
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });
    }
    window.location.reload();
    return true;
}