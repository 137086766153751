import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {IHistory} from '../../interfaces';
import {submitInspectionTypeForm} from '../../services/formService';
import {BasePage} from '../reusableComponents/BasePage';
import {PlantFormImpl} from './PlantForm';
import {parseJwt} from "../../services/loginService";
import {Col, Row} from 'react-bootstrap';
import {Form} from '../reusableComponents/FormComponents/Form';
import {RRFInput} from '../reusableComponents/FormComponents/RRFInput';
import { isFormEmpty} from '../../utils/generalUtils';
import {EMPTY_FIELD_MESSAGE} from '../../constants/generals';
import {toast} from 'react-toastify'; 
import { Loader } from '../reusableComponents/Loader';

export interface IAddInspectionTypeProps {
    history?: IHistory;
    userUID: any;
    userName: any; 
}
export interface IAddInspectionTypeState {
    inspectionInfo: any;
    showLoader: boolean;
}
export class AddInspectionTypeImpl extends React.PureComponent<IAddInspectionTypeProps, IAddInspectionTypeState> {

    constructor(props: IAddInspectionTypeProps) {
        super(props);
        this.state = { inspectionInfo: {
            facilityType: '', permitType: '', frequency: '',  inspectionType: '',  inspectionName: '' }, showLoader: false,
        }
    }

    onSubmitForm = async () => {   
        if (isFormEmpty(this.state.inspectionInfo)) {
            toast.error(EMPTY_FIELD_MESSAGE, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            return;
        } 
        this.setState({showLoader: true, })
        let res = await submitInspectionTypeForm(this.state.inspectionInfo, this.props.history);  
        this.setState({showLoader: false, })
    }

    onFieldValueChange = (field, id?: string) => {
        const inspectionInfo = JSON.parse(JSON.stringify(this.state.inspectionInfo));
        if (id) {
            inspectionInfo[id] = field.value;
        } else {
            inspectionInfo[field.target.id] = field.target.value;
        }
        this.setState({ 
            inspectionInfo
        });
    }

    render() {
        return <BasePage key={3} pageTitle="Inspection Type">
             <Row>
                <Col className="plant-basic-data"> 
                {this.state.showLoader ? <Loader key={1} type="submission"/> : ''}
                    <Form model="forms.inspectionType" onSubmit={this.onSubmitForm}>
                            <RRFInput
                                model=".facilityType"
                                type="dropdown"
                                placeholder="Select a Facility Type"
                                id="facilityType"
                                onSelect={this.onFieldValueChange}
                                menuItems={[{
                                    label: 'Ready Mix', value: 'Ready Mix'
                                },
                                    {
                                        label: 'Quarry', value: 'Quarry'
                                    },
                                    {
                                        label: 'Asphalt', value: 'Asphalt'
                                    },
                                    {
                                        label: 'Maintenance Shop', value: 'Maintenance Shop'
                                    },
                                    {
                                        label: 'Others', value: 'Others'
                                    }
                                ]}
                                label="Facility Type" 
                            />
                            <RRFInput
                                model=".permitType"
                                type="dropdown"
                                placeholder="Select a Permit Type"
                                id="permitType"
                                onSelect={this.onFieldValueChange}
                                menuItems={[{
                                    label: 'Water', value: 'Water'
                                },
                                    {
                                        label: 'Air', value: 'Air'
                                    },
                                    {
                                        label: 'Waste', value: 'Waste'
                                    },
                                    {
                                        label: 'Others', value: 'Others'
                                    }
                                ]}
                                label="Permit Type" 
                            />
                        <RRFInput
                                model=".frequency"
                                type="dropdown"
                                placeholder="Select a Frequency"
                                id="frequency"
                                onSelect={this.onFieldValueChange}
                                menuItems={[{
                                        label: 'Daily', value: 'Daily'
                                    },
                                    {
                                        label: 'Weekly', value: 'Weekly'
                                    }, 
                                    {
                                        label: 'Monthly', value: 'Monthly'
                                    },
                                    {
                                        label: 'Quarterly', value: 'Quarterly'
                                    }, 
                                    {
                                        label: 'Yearly', value: 'Yearly'
                                    }, 
                                ]}
                                label="Frequency" 
                            />
                        <RRFInput
                                model=".inspectionType"
                                type="dropdown"
                                placeholder="Select a Inspection Type"
                                id="inspectionType"
                                onSelect={this.onFieldValueChange}
                                menuItems={[{
                                        label: 'Inspections', value: 'Inspections'
                                    },
                                    {
                                        label: 'HouseKeeping', value: 'HouseKeeping'
                                    }, 
                                ]}
                                label="Inspection Type" 
                            />
                        <RRFInput
                            model=".inspectionName"
                            type="text"
                            placeholder="Enter Inspection Name"
                            id="inspectionName"
                            label="Inspection Name"
                            onChange={this.onFieldValueChange}
                            defaultValue=''
                            /> 
                    </Form>
                </Col>
            </Row>
            <div className="form-footer"> 
                <button type="button" onClick={this.onSubmitForm} className="submit-button">
                    Submit
                </button>
            </div>
        </BasePage>;
    }
}

function mapStateToProps(state: any, ownProps: any) {
    const userName = parseJwt(state.login.get('token')).First_Name;
    return {
        userUID: state.login.get('UniqueId'),
        userName
    };
}

export const AddInspectionTypeForm = withRouter(connect< IAddInspectionTypeProps, any, any >(mapStateToProps)(AddInspectionTypeImpl as any));
