import * as React from 'react';
import {BaseModel} from './BaseModel';
import {LOCAL_STORAGE_KEY} from '../constants/generals';

interface IUserModelProps {
    id: string;
    UniqueId: string;
    UserId: number;
    Job_Title: string;
    Regions: string[];
    orgId: string;
    orgName?: string;
    Access_Level: string;
    Last_Name: string;
    Job_Desc: string;
    Last_Updated: string;
    Date_Created: string;
    Middle_Name: string;
    Plants: string[];
    First_Name: string;
    Email: string;
    Active: number;
    TotalCount?: any;
}

export class UserModel extends BaseModel<IUserModelProps> {
    static resource = 'user';

    constructor(props: IUserModelProps) {
        super(props, 'user');
    }

    static getUserUID = () => {
        const data = localStorage.getItem(LOCAL_STORAGE_KEY);
        const userInfo = JSON.parse(data || '');
        return userInfo.userUID;
    }

    static getUserData = () => {
        const data = localStorage.getItem(LOCAL_STORAGE_KEY);
        return JSON.parse(data || '');
    }

    static getCompanyName = () => {
        const data = localStorage.getItem(LOCAL_STORAGE_KEY);


       const userInfo = JSON.parse(data || '');
        return userInfo.orgName;
    }

    static checkUserAccess(reqAccess: string = '') {
        if (!reqAccess) {
            return false;
        }
        const data: any = localStorage.getItem(LOCAL_STORAGE_KEY);
        const userInfo = JSON.parse(data);
        return userInfo.Access_Level === 'L2';
    }

} 
